import moment from 'moment/moment';
import { metersFormats } from '../utils/enums/sensors/meters-formats';
import { UNITS } from '../utils/units.enum';
import { getContainer } from '@vegga/front-store';
import { UNITS_OF_MEASUREMENT_ENUM } from '@vegga/front-utils';
import { SLOGICS_OPS } from '../utils/enums/sensors/slogics-operations-type-enum';

import {
  A2500_DEFAULT_CONFIG,
  A4500_DEFAULT_CONFIG,
  A5500_DEFAULT_CONFIG,
  A7000_DEFAULT_CONFIG,
  ABIT_DEFAULT_CONFIG,
} from '../utils/device-config';
import { Subject, takeUntil } from 'rxjs';

const DIGITAL_SENSOR_IMAGE_ENUM = {
  DS_ERROR: 'error',
  DS_OPENED: 'opened',
  DS_CLOSED: 'closed',
};

(function () {
  'use strict';

  angular.module('agronicwebApp').constant('moment', moment).controller('sensorsController', sensorsController);

  sensorsController.$inject = [
    '$scope',
    '$state',
    'progFactory',
    'sensorsFactory',
    '$q',
    '$anchorScroll',
    'sectorFactory',
    'resFactory',
    '$confirm',
    '$filter',
    'historyUtilsFactory',
    '$rootScope',
  ];

  function sensorsController(
    $scope,
    $state,
    progFactory,
    sensorsFactory,
    $q,
    $anchorScroll,
    sectorFactory,
    resFactory,
    $confirm,
    $filter,
    historyUtilsFactory,
    $rootScope
  ) {
    var vm = this;

    vm.activeAll;
    vm.activeDigitals;
    vm.activeAnalogs;
    vm.activeCounters;
    vm.activeLogics;
    vm.currentUnit;
    vm.digitals;
    vm.overlayEl;
    vm.cancel;
    vm.save;
    vm.UNITS = UNITS;
    vm.DIGITAL_SENSOR_IMAGE_ENUM = DIGITAL_SENSOR_IMAGE_ENUM;
    vm.history_type = 0;
    vm.historyFacade = getContainer().resolve('historyFacade');
    vm.destroy$ = new Subject();
    vm.activeAll = true;
    vm.showSensors = showSensors;
    // vm.openSensor = openSensor;
    vm.closeSensor = closeSensor;
    vm.changeToProgram = changeToProgram;
    vm.showOverlay = showOverlay;
    vm.checked = false;
    vm.cancel = cancel;
    vm.save = save;
    vm.changeState = changeState;
    vm.openSensorDetails = openSensorDetails;
    vm.loadHistoryData = loadHistoryData;
    vm.updateRangeDates = updateRangeDates;
    vm.destroySubscriptions = destroySubscriptions;
    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.SLOGICS_OPS = SLOGICS_OPS;

    vm.sensorOperations = [
      $filter('translate')('sensors.operations.inactive'),
      $filter('translate')('sensors.operations.sum'),
      $filter('translate')('sensors.operations.difference'),
      $filter('translate')('sensors.operations.average'),
      $filter('translate')('sensors.operations.and'),
      $filter('translate')('sensors.operations.or'),
    ];

    const sensorsHistoryTypesEnum = {
      METER: 'meter',
      ANALOG: 'analog',
      COUNTER: 'counter',
      DIGITAL: 'digital',
      LOGIC: 'logic',
    };

    activate();
    // initSubscriptions();
    function activate() {
      vm.currentState = $state.includes;
      vm.devicesFacade.legacyUnitResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((currentUnit) => {
        if (!currentUnit) {
          $state.go('units');
        }
        vm.currentUnit = currentUnit;
        loadData(currentUnit);
      });
    }

    function loadData() {
      $q.all([loadDigitals().then(), loadAnalogs().then(), loadActiveLogics().then()]).then(() => {
        if (vm.currentUnit.type === UNITS.A_4000) {
          checkConditioners();
        }
      });
      if (vm.currentUnit.type === UNITS.A_4500) {
        sensorsFactory.analogformatsViewAll(vm.currentUnit.id).then((data) => {
          vm.A4500Formats = data.plain();
        });
      } else {
        loadConditionersLiterals();
      }

      vm.from = historyUtilsFactory.from;
      vm.to = historyUtilsFactory.to;

      vm.avgMinMaxGridColumnDef = [
        {
          field: 'from',
          headerName: $filter('translate')('register.date'),
          cellTemplate: (val) => `<vegga-text>${val.from}</vegga-text>`,
          width: 300,
        },
        {
          field: 'min',
          headerName: $filter('translate')('history.min'),
          cellTemplate: ({ min, units }) =>
            vm.currentUnit.type === UNITS.A_2500 || vm.currentUnit.type === UNITS.A_BIT
              ? `<vegga-text>${min} ${units}</vegga-text>`
              : `<vegga-text>${getSensorValueCellTemplate(min)}</vegga-text>`,
        },
        {
          field: 'max',
          headerName: $filter('translate')('history.max'),
          cellTemplate: ({ max, units }) =>
            vm.currentUnit.type === UNITS.A_2500 || vm.currentUnit.type === UNITS.A_BIT
              ? `<vegga-text>${max} ${units}</vegga-text>`
              : `<vegga-text>${getSensorValueCellTemplate(max)}</vegga-text>`,
        },
        {
          field: 'avg',
          headerName: $filter('translate')('history.avg'),
          cellTemplate: ({ avg, units }) =>
            vm.currentUnit.type === UNITS.A_2500 || vm.currentUnit.type === UNITS.A_BIT
              ? `<vegga-text>${avg} ${units}</vegga-text>`
              : `<vegga-text>${getSensorValueCellTemplate(avg)}</vegga-text>`,
        },
      ];
      vm.avgGridColumnDef = [
        {
          field: 'date',
          headerName: $filter('translate')('register.date'),
          cellTemplate: (val) => `<vegga-text>${val.date}</vegga-text>`,
          width: 300,
        },
        {
          field: 'value',
          headerName: $filter('translate')('cond.value'),
          cellTemplate: ({ value }) => getSensorValueCellTemplate(value),
        },
      ];
    }

    function getColDef() {
      vm.avgMinMaxGridColumnDef = getAvgMaxMinColDef();

      vm.avgGridColumnDef = getAvgColDef();
    }

    function getAvgMaxMinColDef() {
      return vm.currentUnit.type === UNITS.A_2500 ||
        vm.currentUnit.type === UNITS.A_4500 ||
        vm.currentUnit.type === UNITS.A_4000
        ? [
            {
              field: 'dateFrom',
              headerName: $filter('translate')('register.date'),
              width: 300,
              contentStyle: 'date',
              unit: 'day',
            },
            {
              field: 'minValue',
              headerName: $filter('translate')('history.min'),
              cellTemplate: ({ minValue, unit }) =>
                `<vegga-text>${minValue} ${unit === 'UNKNOWN' ? 'n/a' : unit}</vegga-text>`,
            },
            {
              field: 'maxValue',
              headerName: $filter('translate')('history.max'),
              cellTemplate: ({ maxValue, unit }) =>
                `<vegga-text>${maxValue} ${unit === 'UNKNOWN' ? 'n/a' : unit}</vegga-text>`,
            },
            {
              field: 'avgValue',
              headerName: $filter('translate')('history.avg'),
              cellTemplate: ({ avgValue, unit }) =>
                `<vegga-text>${avgValue} ${unit === 'UNKNOWN' ? 'n/a' : unit}</vegga-text>`,
            },
          ]
        : [
            {
              field: 'from',
              headerName: $filter('translate')('register.date'),
              cellTemplate: (val) => `<vegga-text>${val.from}</vegga-text>`,
              width: 300,
              contentStyle: 'date',
              unit: 'day',
            },
            {
              field: 'min',
              headerName: $filter('translate')('history.min'),
              cellTemplate: ({ min, units }) =>
                vm.currentUnit.type === UNITS.A_2500 || vm.currentUnit.type === UNITS.A_BIT
                  ? `<vegga-text>${min} ${units}</vegga-text>`
                  : `<vegga-text>${getSensorValueCellTemplate(min)}</vegga-text>`,
            },
            {
              field: 'max',
              headerName: $filter('translate')('history.max'),
              cellTemplate: ({ max, units }) =>
                vm.currentUnit.type === UNITS.A_2500 || vm.currentUnit.type === UNITS.A_BIT
                  ? `<vegga-text>${max} ${units}</vegga-text>`
                  : `<vegga-text>${getSensorValueCellTemplate(max)}</vegga-text>`,
            },
            {
              field: 'avg',
              headerName: $filter('translate')('history.avg'),
              cellTemplate: ({ avg, units }) =>
                vm.currentUnit.type === UNITS.A_2500 || vm.currentUnit.type === UNITS.A_BIT
                  ? `<vegga-text>${avg} ${units}</vegga-text>`
                  : `<vegga-text>${getSensorValueCellTemplate(avg)}</vegga-text>`,
            },
          ];
    }

    function getAvgColDef() {
      if (
        vm.currentUnit.type === UNITS.A_2500 ||
        vm.currentUnit.type === UNITS.A_4500 ||
        vm.currentUnit.type === UNITS.A_4000
      ) {
        return vm.selectedSensor.classType === 'analog' || vm.selectedSensor.classType === 'logic'
          ? [
              {
                field: 'dateFrom',
                headerName: $filter('translate')('register.date'),
                contentStyle: 'date',
                unit: 'day',
              },
              {
                field: 'avgValue',
                headerName: $filter('translate')('cond.value'),
                cellTemplate: ({ avgValue, unit }) =>
                  `<vegga-text>${avgValue} ${unit === 'UNKNOWN' ? 'n/a' : unit}</vegga-text>`,
              },
            ]
          : [
              {
                field: 'dateFrom',
                headerName: $filter('translate')('register.date'),
                contentStyle: 'date',
                unit: 'day',
              },
              {
                field: 'volume',
                headerName: $filter('translate')('cond.value'),
                cellTemplate: ({ volume, unit, avgValue }) => {
                  if (
                    vm.currentUnit.type === UNITS.A_4000 &&
                    vm.selectedSensor.classType === sensorsHistoryTypesEnum.DIGITAL
                  ) {
                    return `<vegga-text>${avgValue} ${unit && UNITS_OF_MEASUREMENT_ENUM[unit].SYMBOL}</vegga-text>`;
                  }
                  const value = volume?.value !== undefined ? volume?.value : volume;
                  const sensorUnit = volume?.unit || unit;
                  return `<vegga-text>${value} ${UNITS_OF_MEASUREMENT_ENUM[sensorUnit].SYMBOL}</vegga-text>`;
                },
              },
            ];
      }
      return [
        {
          field: 'date',
          headerName: $filter('translate')('register.date'),
          cellTemplate: (val) => `<vegga-text>${val.date}</vegga-text>`,
          width: 300,
          contentStyle: 'date',
          unit: 'day',
        },
        {
          field: 'value',
          headerName: $filter('translate')('cond.value'),
          cellTemplate: ({ value }) => getSensorValueCellTemplate(value),
        },
      ];
    }
    function initSubscriptions() {
      vm.historyFacade.historyResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((res) => {
        vm.gridData = res.items;

        if (vm.history_type === 0) {
          loadBarChart();
        } else {
          loadLineChart();
        }
        vm.loadingGridData = false;
      });
    }
    function getSensorValueCellTemplate(val) {
      return `<vegga-text>${
        vm.selectedSensor.classType === 'meter' || vm.selectedSensor.classType === 'counter'
          ? fixNumberValues(formatMeterValue(val, vm.selectedSensor.meterType))
          : vm.selectedSensor.classType === 'analog' || vm.selectedSensor.classType === 'logic'
          ? fixNumberValues(formatAnalog(val))
          : val
      } ${vm.sensorUnit}</vegga-text>`;
    }

    function showOverlay(state) {
      if (state !== 'sensors.formats') {
        if (vm.activeCounters && vm.currentUnit.type !== UNITS.A_4000) {
          state = state + '.meters';
        } else if (vm.activeAnalogs) {
          state = state + '.as';
        } else if (vm.activeLogics) {
          state = state + '.logics';
        } else {
          state = state + '.ds';
        }
      }

      $state.go(state, { unit: vm.currentUnit });

      $rootScope.$emit('preventSkeleton', true);
      vm.overlayEl = document.querySelector('#sensor-config-overlay');
      vm.overlayEl.show();
    }

    function getFlowFormats() {
      switch (vm.currentUnit.type) {
        case UNITS.A_2500:
          vm.meterFormats = A2500_DEFAULT_CONFIG.measurementUnits.flow;
          vm.accumulatedFormats = A2500_DEFAULT_CONFIG.formats.metersAccumulated;
          break;
        case UNITS.A_4000:
          break;
        case UNITS.A_4500:
          vm.meterFormats = [
            A4500_DEFAULT_CONFIG.formats.metersFlow.energy,
            A4500_DEFAULT_CONFIG.formats.metersFlow.unit,
            A4500_DEFAULT_CONFIG.formats.metersFlow.volume,
          ].flat();
          vm.accumulatedFormats = [
            A4500_DEFAULT_CONFIG.formats.metersAccumulated.volume,
            A4500_DEFAULT_CONFIG.formats.metersAccumulated.energy,
            A4500_DEFAULT_CONFIG.formats.metersAccumulated.unit,
          ].flat();
          break;
        case UNITS.A_5500:
          vm.accumulatedFormats = A5500_DEFAULT_CONFIG.formats.metersAccumulated;
          break;
        case UNITS.A_7000:
          vm.accumulatedFormats = A7000_DEFAULT_CONFIG.formats.metersAccumulated;
          break;
        case UNITS.A_BIT:
          vm.meterFormats = ABIT_DEFAULT_CONFIG.measurementUnits.flow;
          vm.accumulatedFormats = ABIT_DEFAULT_CONFIG.formats.metersAccumulated;
          break;
        default:
          break;
      }
    }

    function openSensorDetails(sensor) {
      if (
        sensor.operation === SLOGICS_OPS.AND ||
        sensor.operation === SLOGICS_OPS.OR ||
        sensor.operation === SLOGICS_OPS.INACTIVE
      ) {
        return;
      }
      vm.history_type = 0;
      initSubscriptions();
      vm.selectedSensor = sensor;
      getColDef();

      vm.loadingGridData = true;
      vm.from = historyUtilsFactory.from;
      vm.to = historyUtilsFactory.to;

      getFlowFormats();
      if (sensor.classType === 'meter') {
        vm.selectedSensor.meter = true;
      }

      document.querySelector('#sensor-detail-overlay').show();
      if (sensor.classType === 'digital' && vm.currentUnit.type === vm.UNITS.A_4000) {
        vm.selectedSensor.meter = true;
        return;
      }
    }

    function loadHistoryData(fromDropdownChange) {
      if (
        vm.currentUnit.type === UNITS.A_2500 ||
        vm.currentUnit.type === UNITS.A_4500 ||
        vm.currentUnit.type === UNITS.A_4000
      ) {
        getHistory();

        if (!fromDropdownChange) return;
        if (vm.history_type === 0) {
          loadBarChart();
        } else {
          loadLineChart();
        }
        return;
      }
      vm.history = false;
      vm.gridData = [];
      switch (vm.selectedSensor.classType) {
        case 'analog':
        case 'logic':
          vm.selectedSensorType = $filter('translate')('sensors.analog');
          vm.loadingGridData = true;
          sensorsFactory
            .registerAnalogs(vm.currentUnit.id, vm.selectedSensor.pk.id, vm.currentUnit.type, vm.from, vm.to)
            .then(function (data) {
              if (!data.plain().length) {
                vm.loadingGridData = false;
                vm.isAnalogsDataEmtpy = true;
                return;
              }
              if (
                (vm.currentUnit.type === UNITS.A_5500 || vm.currentUnit.type === UNITS.A_4500) &&
                !Object.keys(data.plain()[0]).length
              ) {
                vm.isAnalogsDataEmtpy = true;
                vm.loadingGridData = false;
                return;
              }

              vm.isAnalogsDataEmtpy = false;

              if (vm.history_type === 0) {
                prepareBarChart(data.plain(), vm.selectedSensor.classType);
                vm.gridData = data.plain();
              }
            });

          sensorsFactory
            .historyAnalogs(vm.currentUnit.id, vm.selectedSensor.pk.id, vm.from, vm.to)
            .then(function (data) {
              if (!data.length) {
                vm.isAvgDataEmpty = true;
                vm.loadingGridData = false;
                return;
              }

              vm.isAvgDataEmpty = false;
              if (vm.history_type === 1) {
                vm.gridData = data;

                prepareLineChart(data);
              }
            });

          break;

        case 'meter':
          vm.loadingGridData = true;
          vm.selectedSensorType = $filter('translate')('sensors.scount');
          vm.history_type = 0;
          if (
            vm.currentUnit.type === UNITS.A_2500 ||
            vm.currentUnit.type === UNITS.A_BIT ||
            vm.currentUnit.type === UNITS.A_4500
          ) {
            sensorsFactory
              .registerDigitals(vm.currentUnit.id, vm.selectedSensor.pk.id, vm.currentUnit.type, vm.from, vm.to)
              .then(function (data) {
                if (!data.plain().length) {
                  vm.isAnalogsDataEmtpy = true;
                  vm.loadingGridData = false;
                  return;
                }

                vm.gridData = data.plain();

                prepareBarChart(data.plain(), 'meter');

                vm.isAnalogsDataEmtpy = false;
              });
          }
          break;
        case 'digital':
          // Backend is not prepared to retrieve A5500 and A7000 register/history
          if (vm.currentUnit.type === UNITS.A_4000) {
            vm.history_type = 0;
            sensorsFactory
              .registerDigitals(vm.currentUnit.id, vm.selectedSensor.pk.id, vm.currentUnit.type, vm.from, vm.to)
              .then(function (data) {
                if (!data.plain().length) {
                  vm.isAnalogsDataEmtpy = true;
                  vm.loadingGridData = false;
                  return;
                }

                vm.gridData = data.plain();

                prepareBarChart(data.plain(), 'meter');

                vm.isAnalogsDataEmtpy = false;
              });
          }
          break;
        default:
          vm.history_type = 0;
          break;
      }
    }

    function updateRangeDates(e) {
      const date = e.detail;

      const [start, end] = date;

      vm.from = start;
      vm.to = end;
      loadHistoryData();
    }

    function prepareLineChart() {
      const minValues = vm.gridData.map((item) => fixNumberValues(item.min));
      const maxValues = vm.gridData.map((item) => fixNumberValues(item.max));
      const avgValues = vm.gridData.map((item) => fixNumberValues(item.avg));
      const lineChartLabels = vm.gridData
        .map((item) => item.from.split(' ')[0])
        .map((date) => moment(date, 'DD-MM-YYYY').format('YYYY/MM/DD'));

      vm.sensorUnit = vm.selectedSensor.value.split(' ')[1];

      vm.lineChart = {
        title: {
          text: '',
        },

        xAxis: {
          categories: lineChartLabels,
        },

        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
          },
        },

        tooltip: {
          split: true,
          shared: true,
          style: {
            fontSize: 'var(--vegga-text-md-font-size)',
          },
          format:
            '<span style="font-size: 0.8em">{key}</span><br/>' +
            '{#each points}' +
            '<span style="color:{color}">\u25CF</span> ' +
            `{series.name}: <b>{y} ${vm.sensorUnit}</b><br/>` +
            '{/each}',
        },

        series: [
          {
            name: $filter('translate')('history.min'),
            data: minValues,
          },
          {
            name: $filter('translate')('history.max'),
            data: maxValues,
          },
          {
            name: $filter('translate')('history.avg'),
            data: avgValues,
          },
        ],
      };
      vm.loadingGridData = false;
      vm.history = true;
    }

    function closeSensor() {
      vm.checked = false;
      vm.selected = {};
      vm.data = [];
    }

    function loadDigitals() {
      vm.digitals = [];
      return sensorsFactory.digitalsactive(vm.currentUnit.id).then(function (data) {
        let sensors = data;
        if (vm.currentUnit.type === vm.UNITS.A_4000 || vm.currentUnit.type === vm.UNITS.A_7000) {
          let counters = extractCounters(sensors);
          sensors = _.xor(sensors, counters);
          vm.digitals = sensors;

          vm.counters = counters;
          prepareCounters(vm.counters);
        } else {
          vm.digitals = sensors;

          loadCounters();
        }

        vm.digitals = vm.digitals.map((digitalSensor) => {
          return {
            ...digitalSensor,
            card: getDigitalSensorState(
              digitalSensor.activatedClose || digitalSensor.activedClosed,
              digitalSensor.xStatus || digitalSensor.xState,
              digitalSensor.xActive || null
            ),
          };
        });
        return 1;
      });
    }

    function loadCounters() {
      return sensorsFactory.meters(vm.currentUnit.id).then((data) => {
        // meterType means that is a summation counter, and a summation counter is not assigned to any input
        vm.counters = data.plain().filter((o) => o.input !== 0 || +o.meterType === 3);

        if (vm.currentUnit.progtype === vm.UNITS.A_4000.toString()) {
          prepareCounters(vm.counters);
        } else if (vm.currentUnit.type === UNITS.A_4500) {
          prepareCountersA4500(vm.counters);
        } else {
          prepareCountersA2(vm.counters);
        }
      });
    }

    function loadAnalogs() {
      vm.analogs = [];
      return sensorsFactory.analogsactive(vm.currentUnit.id).then(function (data) {
        var sensors = data.filter((sensor) => sensor.input !== 0);

        checkFormats(sensors);
        vm.analogs = sensors;
        return 1;
      });
    }

    function loadActiveLogics() {
      vm.activatedLogics = [];
      return sensorsFactory.getActiveLogics(vm.currentUnit.id).then((data) => {
        data.forEach((sensor) => {
          sensor.classType = 'logic';
        });
        const parsedLogics = parseLogicValue(data.plain());
        vm.activatedLogics = parsedLogics;
      });
    }

    function parseLogicValue(logics) {
      return logics.map((sl) => ({ ...sl, parsedValue: getValueByType(sl) }));
    }

    function getValueByType(sensor) {
      let elementType = null;
      switch (sensor.operation) {
        case 1:
        case 2:
        case 3:
          for (let element of sensor.elements) {
            if (element.elementType !== null) {
              elementType = element.elementType;
              break;
            }
          }

          if (elementType === 1) {
            const formats = metersFormats;
            let format = formats[0][sensor.sensorUnit];
            return `${sensor.xLecture} ${format ? format.label : ''}`;
          }

          return `${sensor.xLecture} ${sensor.sensorUnit ? sensor.sensorUnit : ''}`;
        default:
          return sensor.xLecture;
      }
    }

    function extractCounters(sensors) {
      if (vm.currentUnit.type === UNITS.A_4000) {
        return sensors.filter((o) => Number(o.pk.id) >= 11 && Number(o.pk.id) <= 35);
      } else {
        return sensors.filter(
          (o) => (Number(o.pk.id) >= 1 && Number(o.pk.id) <= 14) || (Number(o.pk.id) >= 39 && Number(o.pk.id) <= 47)
        );
      }
    }

    function prepareCountersA4500(counters) {
      const formats = metersFormats;

      _.forEach(counters, (c) => {
        var format = formats[0][c.flowFormat];
        if (format) {
          c.format = format;

          // Convert number to string and pad with zeros if necessary
          var numStr = c.xInstantFlow.toString().padStart(format.decimals + 1, '0');

          // If sensor is a pluviometer, has 2 decimals
          if (c.meterType === 4) {
            format.decimals = 2;
          }
          // Insert decimal point at specified position
          const formatted =
            format.decimals > 0
              ? numStr.slice(0, -format.decimals) + '.' + numStr.slice(-format.decimals)
              : numStr.slice(-format.decimals);
          // Pluviometre

          c.formattedFlow = formatted + ' ' + format.label;
        }

        // set color
        switch (c.xUsedBy) {
          case 0:
            c.color = 'success';
            break;
          case 1:
            c.color = 'info';
            break;
          case 2:
            c.color = 'warning';
            break;
          default:
            c.color = 'success';
            break;
        }
      });
    }

    function prepareCountersA2(counters) {
      var formats = {
        0: 'm3/h',
        1: 'L/h',
        2: 'L/s',
      };
      _.forEach(counters, (c) => {
        if (
          ((vm.currentUnit.type === UNITS.A_2500 || vm.currentUnit.type === UNITS.A_BIT) && c.meterType !== 1) ||
          (vm.currentUnit.type === UNITS.A_5500 && c.type !== 3)
        ) {
          //Tipus digitial NO pluviometre

          var decimals = 2;
          if (c.xFlow === null) c.xFlow = 0;
          c.xFlow = c.xFlow.toString();
          var first = c.xFlow.slice(0, c.xFlow.length - decimals);
          var last = c.xFlow.slice(c.xFlow.length - decimals, c.xFlow.length);
          c.xFlow = first + '.' + last;
          c.value = Number(c.xFlow) + formats[c.flowFormat];
          switch (c.usedBy) {
            case 0:
              if (Number(c.xFlow) !== 0) {
                c.active = true;
                c.error = Number(c.assigned) !== 0;
              }
              break;
            case 1:
            case 2:
              c.active = true;
              break;
          }
        }
      });
    }

    function prepareCounters(counters) {
      var meters = [];
      var fertmeters = [];
      sensorsFactory.meters(vm.currentUnit.id).then((data1) => {
        sensorsFactory.fertmeters(vm.currentUnit.id).then((data2) => {
          meters = data1.plain();
          fertmeters = data2.plain();
          if (meters.length > 0 || fertmeters.length > 0) {
            _.forEach(counters, (c) => {
              if (vm.currentUnit.type === UNITS.A_4000) {
                switch (c.pk.id) {
                  case '11':
                  case '23':
                    c.valueNumber = parseUnitFlow(meters[0]);
                    c.value = c.valueNumber + ' m3/h';
                    c.active = meters[0].xActive || c.valueNumber > 0;
                    c.meter = 1;
                    break;
                  case '12':
                  case '24':
                    c.valueNumber = parseUnitFlow(meters[1]);
                    c.value = c.valueNumber + ' m3/h';
                    c.active = meters[1].xActive || c.valueNumber > 0;
                    c.meter = 2;
                    break;
                  case '13':
                  case '25':
                    c.valueNumber = parseUnitFlow(meters[2]);
                    c.value = c.valueNumber + ' m3/h';
                    c.active = meters[2].xActive || c.valueNumber > 0;
                    c.meter = 3;
                    break;
                  case '14':
                  case '26':
                    c.valueNumber = parseUnitFlow(meters[3]);
                    c.value = c.valueNumber + ' m3/h';
                    c.active = meters[3].xActive || c.valueNumber > 0;
                    c.meter = 4;
                    break;
                  case '15':
                  case '27':
                    c.valueNumber = parseUnitFert(fertmeters[0]);
                    c.value = c.valueNumber + ' l/h';
                    c.active = fertmeters[0].xActive || c.valueNumber > 0;
                    break;
                  case '16':
                  case '28':
                    c.valueNumber = parseUnitFert(fertmeters[1]);
                    c.value = c.valueNumber + ' l/h';
                    c.active = fertmeters[1].xActive || c.valueNumber > 0;
                    break;
                  case '17':
                  case '29':
                    c.valueNumber = parseUnitFert(fertmeters[2]);
                    c.value = c.valueNumber + ' l/h';
                    c.active = fertmeters[2].xActive || c.valueNumber > 0;
                    break;
                  case '18':
                  case '30':
                    c.valueNumber = parseUnitFert(fertmeters[3]);
                    c.value = c.valueNumber + ' l/h';
                    c.active = fertmeters[3].xActive || c.valueNumber > 0;
                    break;
                  case '19':
                  case '31':
                    c.valueNumber = parseUnitFert(fertmeters[4]);
                    c.value = c.valueNumber + ' l/m2';
                    c.active = fertmeters[4].xActive || c.valueNumber > 0;
                    break;
                  case '20':
                  case '32':
                    c.valueNumber = parseUnitFert(fertmeters[5]);
                    c.value = c.valueNumber + ' l/m2';
                    c.active = fertmeters[5].xActive || c.valueNumber > 0;
                    break;
                  case '21':
                  case '33':
                    c.valueNumber = parseUnitFert(fertmeters[6]);
                    c.value = c.valueNumber + ' l/m2';
                    c.active = fertmeters[6].xActive || c.valueNumber > 0;
                    break;
                  case '22':
                  case '34':
                    c.valueNumber = parseUnitFert(fertmeters[7]);
                    c.value = c.valueNumber + ' l/m2';
                    c.active = fertmeters[7].xActive || c.valueNumber > 0;
                    break;
                  case '35': //Irrigation meter
                    c.active = true;
                    break;
                }
              } else {
                let id = Number(c.pk.id);
                switch (c.pk.id) {
                  case '1':
                  case '2':
                  case '3':
                  case '4':
                  case '5':
                  case '6':
                    c.valueNumber = parseUnitFlow(meters[id - 1]);
                    c.value = c.valueNumber + ' m3/h';
                    c.active = meters[id - 1].xActive || c.valueNumber > 0;
                    c.meter = id;
                    break;
                  case '7':
                  case '8':
                  case '9':
                  case '10':
                  case '11':
                  case '12':
                  case '13':
                  case '14':
                    c.valueNumber = parseUnitFert(meters[id - 1]);
                    c.value = c.valueNumber + ' l/h';
                    c.active = meters[id - 1].xActive || c.valueNumber > 0;
                    c.meter = id;
                    break;
                }
              }
            });
          }
        });
      });
    }

    function parseUnitFlow(value) {
      value = '' + value.xFlow;
      if (vm.currentUnit.installer) {
        var decimals;
        if (vm.currentUnit.installer.instantFlow === 0 || vm.currentUnit.type === UNITS.A_7000) {
          decimals = 2;
        } else {
          decimals = 1;
        }
        var first = value.slice(0, value.length - decimals);
        var last = value.slice(value.length - decimals, value.length);
        value = first + '.' + last;
      }
      return _.toNumber(value);
    }

    function parseUnitFert(value) {
      if (!value) {
        return;
      }
      value = '' + value.xFlow;
      if (vm.currentUnit.installer) {
        var decimals = 1;
        if (vm.currentUnit.installer.instantFlow === 0) {
          decimals = 2;
        }
        var first = value.slice(0, value.length - decimals);
        var last = value.slice(value.length - decimals, value.length);
        value = first + '.' + last;
      }
      return _.toNumber(value);
    }

    function checkFormats(sensors) {
      _.forEach(sensors, (s) => {
        let format =
          vm.currentUnit.type !== UNITS.A_7000 && vm.currentUnit.type !== UNITS.A_4500 && vm.currentUnit.formats !== null
            ? vm.currentUnit.formats[s.formatId - 1]
            : s.format;

        s.decimals = format?.decimals ? format.decimals : null;
        s.integers = format?.integers ? format.integers : null;
        if (s.xState === 1) {
          if (format !== undefined && format !== null) {
            var value = '' + s.xValue;
            if (format.decimals > 0) {
              if (s.xValue < 0) {
                var sign = value.slice(0, 1);
                value = value.slice(1);
              }

              if (format.decimals > value.length) {
                var tlength = format.decimals + value.length;
                value = _.padStart(value, tlength, '0');
              }

              var first = value.slice(0, value.length - format.decimals);
              var last = value.slice(value.length - format.decimals, value.length);

              if (sign === '-') value = sign + '' + first + '.' + last;
              else value = first + '.' + last;
            }
            if (vm.currentUnit.type === vm.UNITS.A_4500) {
              if (format.idMagnitud < 26) {
                value = _.toNumber(value) + ' ' + format.units45.symbol;
              } else {
                value = _.toNumber(value) + ' ' + format.customUnits45.symbol;
              }
            } else {
              value = _.toNumber(value) + ' ' + format.units;
            }
            s.value = value;
          }
        }
      });
    }

    function showSensors(value) {
      switch (value) {
        case 'all':
          vm.activeAll = true;
          vm.activeDigitals = false;
          vm.activeAnalogs = false;
          vm.activeCounters = false;
          vm.activeLogics = false;
          break;
        case 'digitals':
          vm.activeAll = false;
          vm.activeDigitals = true;
          vm.activeAnalogs = false;
          vm.activeCounters = false;
          vm.activeLogics = false;
          break;
        case 'analogs':
          vm.activeAll = false;
          vm.activeDigitals = false;
          vm.activeAnalogs = true;
          vm.activeCounters = false;
          vm.activeLogics = false;
          break;
        case 'counters':
          vm.activeAll = false;
          vm.activeDigitals = false;
          vm.activeAnalogs = false;
          vm.activeCounters = true;
          vm.activeLogics = false;
          break;
        case 'logics':
          vm.activeAll = false;
          vm.activeDigitals = false;
          vm.activeAnalogs = false;
          vm.activeCounters = false;
          vm.activeLogics = true;
          break;
      }
    }

    function formatMeterValue(value, format) {
      switch (vm.selectedSensor.classType) {
        case 'counter':
        case 'meter':
          if (
            (vm.currentUnit.type !== vm.UNITS.A_4500 && vm.selectedSensor.meterType === 1) ||
            vm.selectedSensor.pluviometer
          ) {
            const numStr = value.toString().padStart(2 + 1, '0');
            const formattedValue = numStr.slice(0, -2) + '.' + numStr.slice(-2);

            return +formattedValue;
          }
          if (_.isNumber(format) && vm.currentUnit.type !== vm.UNITS.A_4500) {
            return formatCounterA25(value, format); // A2500,A5500,ABIT,A7000
          } else {
            return value;
          }

        default:
          return value;
      }
    }

    function formatAnalog(value, format7) {
      let format;
      if (vm.currentUnit.type === UNITS.A_4500) {
        format = vm.A4500Formats;
      } else if (vm.currentUnit.type !== UNITS.A_7000) {
        format = vm.currentUnit.formats[vm.selectedSensor.formatId - 1];
      } else {
        format = format7;
      }
      if (format !== undefined) {
        value = '' + value;
        if (format.decimals > 0) {
          var first = value.slice(0, value.length - format.decimals);
          var last = value.slice(value.length - format.decimals, value.length);
          value = first + '.' + last;
        }
        value = _.toNumber(value);
        vm.selectedSensor.label = format.units;
      }
      return value;
    }

    function formatCounterA25(value, format) {
      //        vm.currentUnit.version  <= 218
      switch (format) {
        case 0: // 0000m3 register l.
          if (vm.currentUnit.version <= 218 && vm.currentUnit.type === UNITS.A_2500) value = value / 1000;

          break;
        case 1: // 000.0m3 register l.
          value = value / 1000;

          break;
        case 2: // 000.00m3 register l.
          value = value / 1000;

          break;
        case 3: // 00000l. register l.
          break;
        case 4: // 000.0l register l.
          value = value / 100;

          break;
        case 5: // 000.00l register l.
          value = value / 100;

          break;
        case 6: // 0000l/m2 register l.
          break;
        case 7: // 000.0 l/m2 register cl.
          value = value / 100;

          break;
        case 8: // 000.00 l/m2 register cl.
          value = value / 100;
          break;
        default: // Old
          value = value / 1000;

          break;
      }
      return value;
    }

    function fixNumberValues(val) {
      // val % 1 !== 0 is used to check if number has decimals
      return val % 1 !== 0 ? +val.toFixed(2) : val;
    }

    function getAccumulatedUnits() {
      if (vm.currentUnit.type === vm.UNITS.A_4000) {
        return vm.selectedSensor.value.split(' ')[1];
      }
      const accumulatedFormat = vm.selectedSensor.accumulatedFormat || vm.selectedSensor.acumulatedFormat;
      if (vm.currentUnit.type === vm.UNITS.A_4500) {
        return vm.accumulatedFormats.find((format) => accumulatedFormat === format.deviceFormatKey).format;
      }
      return vm.accumulatedFormats.find((format) => accumulatedFormat === format.deviceFormatKey).format.split(' ')[1];
    }

    function prepareBarChart(data, sensorType) {
      const labels = data
        .map((el) => el.date.split(' ')[0])
        .map((date) => moment(date, 'DD-MM-YYYY').format('YYYY/MM/DD'));
      const values = data
        .map((el) =>
          sensorType === 'analog' || sensorType === 'logic'
            ? fixNumberValues(formatAnalog(el.value))
            : sensorType === 'meter'
            ? formatMeterValue(el.value, vm.selectedSensor.meterType)
            : fixNumberValues(el.value)
        )
        .map((val) => fixNumberValues(val));

      vm.sensorUnit = sensorType === 'meter' ? getAccumulatedUnits() : vm.selectedSensor.value.split(' ')[1];
      if (vm.selectedSensor.meterType === 4 && vm.currentUnit.type === vm.UNITS.A_4500) {
        vm.sensorUnit = vm.selectedSensor.formattedFlow.split(' ')[1];
      }

      vm.barChart = {
        time: {
          useUTC: false,
        },
        title: {
          text: '',
        },
        tooltip: {
          split: true,
          shared: true,
          style: {
            fontSize: 'var(--vegga-text-md-font-size)',
          },

          format:
            '<span style="font-size: 0.8em">{key}</span><br/>' +
            '{#each points}' +
            '<span style="color:{color}">\u25CF</span> ' +
            `{series.name}: <b>{y} ${vm.sensorUnit}</b><br/>` +
            '{/each}',
        },
        chart: {
          alignTicks: false,
          zoomType: 'x',
          type: 'column',
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: labels,
        },
        series: [
          {
            name: $filter('translate')('maps.e31'),
            data: values,
            type: 'column',
          },
        ],
        plotOptions: {
          column: {
            pointPlacement: 'between',
          },
          series: {
            connectNulls: true,
            states: {
              hover: {
                enabled: true,
                lineWidth: 2,
              },
            },
            events: {
              mouseOver: function () {
                this.yAxis.update({
                  labels: {
                    style: {
                      fontSize: '14px',
                      fontWeight: 'bold',
                    },
                  },
                });
              },

              mouseOut: function () {
                this.yAxis.update({
                  labels: {
                    style: {
                      fontSize: '12px',
                      fontWeight: 'normal',
                    },
                  },
                });
              },
            },
          },
        },

        yAxis: [
          {
            visible: true,
            title: {
              text: '',
            },
          },
        ],
      };
      vm.loadingGridData = false;
    }

    function checkConditioners() {
      progFactory.programs(vm.currentUnit.id, vm.currentUnit.type, true).then(function () {
        var activeList = progFactory.activePrograms();
        _.forEach(activeList, (prog) => {
          var cond;
          cond = prog.conditioners.filter((o) => {
            return o.sensor !== 0;
          });
          _.forEach(cond, (c) => {
            if (c.type !== 4) {
              //Analog sensor
              const id = c.sensor + '';
              const index = _.findIndex(vm.analogs, (a) => {
                return a.pk.id === id;
              });
              if (index !== -1) {
                if (!_.isArray(vm.analogs[index].programs)) {
                  vm.analogs[index].programs = [];
                }
                if (!_.isArray(vm.analogs[index].conditioners)) {
                  vm.analogs[index].conditioners = [];
                }
                vm.analogs[index].programs.push(prog);
                vm.analogs[index].conditioners.push(c);
              }
            } else {
              //Digital sensor
              const id = c.sensor + 35 + '';
              const index = _.findIndex(vm.digitals, (d) => {
                return d.id === id;
              });
              if (index !== -1) {
                if (!_.isArray(vm.digitals[index].programs)) {
                  vm.digitals[index].programs = [];
                }
                if (!_.isArray(vm.digitals[index].conditioners)) {
                  vm.digitals[index].conditioners = [];
                }
                vm.digitals[index].programs.push(prog);
                vm.digitals[index].conditioners.push(c);
              }
            }
          });
        });
      });
    }

    function loadConditionersLiterals() {
      vm.conditioners = resFactory.cond(vm.currentUnit.type);
    }

    function changeToProgram(program) {
      if (program !== undefined) {
        $state.go('programs.detail', { program: program, id: program.pk.id, unit: vm.currentUnit });
      }
    }

    /**
     * Funcions que executen el broadcast als child controllers d'Edició i Configuració
     */
    function cancel($event) {
      if (vm.form && vm.form.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          vm.closeSensor();
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          document.querySelector('#sensor-config-overlay').dismiss();
          $state.go('sensors', { unit: vm.currentUnit });
        });
      } else {
        vm.closeSensor();
        document.querySelector('#sensor-config-overlay').dismiss();
        $state.go('sensors', { unit: vm.currentUnit });
      }
    }
    function save() {
      if (vm.form && vm.form.$dirty) {
        $confirm({ text: $filter('translate')('programs.edit.saveq') }).then(() => {
          $scope.$broadcast('formSubmit');
        });
      }
    }

    function changeState(location, params) {
      if (vm.form && vm.form.$dirty) {
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          $state.go(location, params); //Canviem d'estat
        });
      } else {
        $state.go(location, params);
      }
    }

    function getHistory() {
      // We have to use moment to keep compatibility with 7000, 5500 and BIT.
      const dateFrom = moment(vm.from, 'DD-MM-YYYY').format('YYYY-MM-DD');
      const dateTo = moment(vm.to, 'DD-MM-YYYY').format('YYYY-MM-DD');
      const params = {
        id: vm.currentUnit.id,
        from: dateFrom,
        to: dateTo,
        grouping: 'DAY',
        sensor: +vm.selectedSensor.pk.id,
        pageNumber: 1,
        pageSize: moment(vm.to, 'DD-MM-YYYY').diff(moment(vm.from, 'DD-MM-YYYY'), 'days'),
      };
      switch (vm.currentUnit.type) {
        case UNITS.A_2500:
          switch (vm.selectedSensor.classType) {
            case sensorsHistoryTypesEnum.ANALOG:
              vm.historyFacade.loadA2500History(params, 'analogs');
              break;
            case sensorsHistoryTypesEnum.METER:
              vm.historyFacade.loadA2500History(params, 'counters');
              break;
          }
          break;
        case UNITS.A_4500:
          switch (vm.selectedSensor.classType) {
            case sensorsHistoryTypesEnum.ANALOG:
              vm.historyFacade.loadA4500History(params, 'analogs');
              break;
            case sensorsHistoryTypesEnum.METER:
              vm.historyFacade.loadA4500History(params, 'counters');
              break;
            case sensorsHistoryTypesEnum.LOGIC:
              vm.historyFacade.loadA4500History(params, 'logics');
              break;
          }
          break;
        case UNITS.A_4000:
          switch (vm.selectedSensor.classType) {
            case sensorsHistoryTypesEnum.ANALOG:
              vm.historyFacade.loadA4000History(params, 'analogs');
              break;
            case sensorsHistoryTypesEnum.DIGITAL:
              vm.historyFacade.loadA4000History(params, 'counters');
              break;
          }
          break;

        default:
          break;
      }
    }

    function addEmptyDays(labels, values) {
      // We have to use moment to keep compatibility with 7000, 5500 and BIT.
      const lastDay = moment(vm.to, 'DD-MM-YYYY');

      const labelsWithAllDatesInRange = [];
      const valuesWithinAllDatesRange = [];

      let currentDate = moment(vm.from, 'DD-MM-YYYY');
      while (currentDate.isSameOrBefore(lastDay)) {
        labelsWithAllDatesInRange.push(currentDate.format());
        currentDate = currentDate.add(1, 'days');
      }

      // Need to declare tempLabels because vm.to always is the day with 00:00:00 time and history comes with different hours
      const tempLabels = labels.map((lab) => moment(lab).format('YYYY-MM-DD'));
      labelsWithAllDatesInRange.forEach((date) => {
        const index = tempLabels.indexOf(moment(date).format('YYYY-MM-DD'));
        if (index !== -1) {
          valuesWithinAllDatesRange.push(values[index]);
        } else {
          valuesWithinAllDatesRange.push(0);
        }
      });
      return { labelsWithAllDatesInRange, valuesWithinAllDatesRange };
    }

    function loadLineChart() {
      const chartColors = [
        'var(--vegga-color-primary-default)',
        'var(--vegga-color-secondary-default)',
        'var(--vegga-color-blue-water-600)',
        'var(--vegga-color-green-grass-800)',
      ];

      // We have to use moment to keep compatibility with 7000, 5500 and BIT.
      if (!vm.selectedSensor) return;
      const labels = vm.gridData.map(({ dateFrom }) => moment(dateFrom).format());
      const avgValues = vm.gridData.map(({ avgValue }) => avgValue);
      const minValues = vm.gridData.map(({ minValue }) => minValue);
      const maxValues = vm.gridData.map(({ maxValue }) => maxValue);

      const { valuesWithinAllDatesRange: minValuesWithinAllDatesRange } = addEmptyDays(labels, minValues);
      const { valuesWithinAllDatesRange: maxValuesWithinAllDatesRange } = addEmptyDays(labels, maxValues);
      const { valuesWithinAllDatesRange: avgValuesWithinAllDatesRange, labelsWithAllDatesInRange } = addEmptyDays(
        labels,
        avgValues
      );

      vm.lineChart = {
        title: {
          text: '',
        },

        xAxis: {
          categories: labelsWithAllDatesInRange,
        },

        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
          },
        },

        tooltip: {
          split: true,
          shared: true,
          style: {
            fontSize: 'var(--vegga-text-md-font-size)',
          },
          format:
            '<span style="font-size: 0.8em">{key}</span><br/>' +
            '{#each points}' +
            '<span style="color:{color}">\u25CF</span> ' +
            `{series.name}: <b>{y} ${vm.gridData[0]?.unit}</b><br/>` +
            '{/each}',
        },

        series: [
          {
            name: $filter('translate')('history.min'),
            data: minValuesWithinAllDatesRange,
            color: chartColors[0],
          },
          {
            name: $filter('translate')('history.max'),
            data: maxValuesWithinAllDatesRange,
            color: chartColors[1],
          },
          {
            name: $filter('translate')('history.avg'),
            data: avgValuesWithinAllDatesRange,
            color: chartColors[2],
          },
        ],
      };
      vm.loadingGridData = false;
      vm.isAvgDataEmpty = !vm.gridData?.length;
    }

    function loadBarChart() {
      const chartColors = [
        'var(--vegga-color-primary-default)',
        'var(--vegga-color-secondary-default)',
        'var(--vegga-color-blue-water-600)',
        'var(--vegga-color-green-grass-800)',
      ];
      if (!vm.selectedSensor) return;
      const labels = vm.gridData.map(({ dateFrom }) => moment(dateFrom).format());
      const values =
        vm.selectedSensor.classType === 'analog' || vm.selectedSensor.classType === 'logic'
          ? vm.gridData.map(({ avgValue }) => avgValue)
          : vm.currentUnit.type === UNITS.A_4500
          ? vm.gridData.map(({ volume }) => volume)
          : vm.selectedSensor.classType === sensorsHistoryTypesEnum.DIGITAL && vm.currentUnit.type === UNITS.A_4000
          ? vm.gridData.map(({ avgValue }) => avgValue)
          : vm.gridData.map(({ volume }) => volume?.value || volume);

      const { labelsWithAllDatesInRange, valuesWithinAllDatesRange } = addEmptyDays(labels, values);

      const unitLabel =
        vm.selectedSensor.classType === 'analog' || vm.selectedSensor.classType === 'logic'
          ? vm.gridData[0]?.unit
          : UNITS_OF_MEASUREMENT_ENUM[vm.gridData[0]?.unit]?.SYMBOL ||
            UNITS_OF_MEASUREMENT_ENUM[vm.gridData[0]?.volume.unit]?.SYMBOL;

      const unitsInChart =
        vm.selectedSensor.classType !== 'analog' &&
        vm.selectedSensor.classType !== 'logic' &&
        vm.currentUnit.type === UNITS.A_4500
          ? vm.gridData.map(({ volume }) => volume.unit)
          : [];

      const distinctUnitsInChart = Array.from(new Set(unitsInChart));

      vm.barChart = {
        time: {
          useUTC: false,
        },
        title: {
          text: '',
        },
        tooltip: {
          split: true,
          shared: true,
          style: {
            fontSize: 'var(--vegga-text-md-font-size)',
          },
          formatter: function () {
            const serieIndex = this.points.find((point) => point.y > 0)?.series?.index;
            const point = this.points[serieIndex];

            if (!point) return '';
            const unit = distinctUnitsInChart[serieIndex];
            return `${point.x} <br/> <b>${point.y} ${UNITS_OF_MEASUREMENT_ENUM[unit]?.SYMBOL || unitLabel}</b>`;
          },
        },
        chart: {
          alignTicks: false,
          zoomType: 'x',
          type: 'column',
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          categories: labelsWithAllDatesInRange,
        },
        series:
          vm.currentUnit.type === UNITS.A_4500 &&
          vm.selectedSensor.classType !== 'analog' &&
          vm.selectedSensor.classType !== 'logic'
            ? distinctUnitsInChart.map((unit, i) => ({
                labels: `{value} ${UNITS_OF_MEASUREMENT_ENUM[unit].SYMBOL}`,
                data: distinctUnitsInChart.map((unit) => {
                  return valuesWithinAllDatesRange.map((item) => (item ? (item.unit === unit ? item.value : 0) : 0));
                })[i],
                yAxis: i,
                type: 'column',
                color: chartColors[i],
                showInLegend: false,
              }))
            : [
                {
                  name: $filter('translate')('maps.e31'),
                  data: valuesWithinAllDatesRange,
                  color: chartColors[0],
                  type: 'column',
                },
              ],
        plotOptions: {
          column: {
            pointPlacement: 'between',
          },
          series: {
            connectNulls: true,
            states: {
              hover: {
                enabled: true,
                lineWidth: 2,
              },
            },
            events: {
              mouseOver: function () {
                this.yAxis.update({
                  labels: {
                    style: {
                      fontWeight: 'bold',
                    },
                  },
                });
              },

              mouseOut: function () {
                this.yAxis.update({
                  labels: {
                    style: {
                      fontWeight: 'normal',
                    },
                  },
                });
              },
            },
          },
        },

        yAxis:
          vm.currentUnit.type === UNITS.A_4500 &&
          vm.selectedSensor.classType !== 'analog' &&
          vm.selectedSensor.classType !== 'logic'
            ? distinctUnitsInChart.map((unit, i) => ({
                visible: true,
                labels: {
                  format: `{text} ${UNITS_OF_MEASUREMENT_ENUM[unit].SYMBOL}`,
                  x: -5,
                  style: {
                    fontSize: '12px',
                  },
                },
                title: {
                  text: undefined,
                },
                lineColor: chartColors[i],
                lineWidth: 1,
                margin: 10,
              }))
            : [
                {
                  visible: true,
                  title: {
                    text: '',
                  },
                },
              ],
      };
      vm.loadingGridData = false;
      vm.isAnalogsDataEmtpy = !vm.gridData?.length;
    }

    /**
     * Function that is used to obtain all the information necessary to paint the digital sensor card
     * @param configNormallyOpen
     * @param state
     * @param active
     * @returns {{image: string, additionalText: string, stateSensor: number}}
     */
    function getDigitalSensorState(configNormallyOpen, state, active) {
      let digitalSensorCard = {
        image: '',
        stateSensor: 0, //  Possible values: 0: No Active, 1: Active, 2: Error
        additionalText: '',
      };

      switch (vm.currentUnit.type) {
        case UNITS.A_4500:
        case UNITS.A_5500:
          digitalSensorCard.stateSensor = state < 0 || state > 1 ? 2 : state === 1 && active ? 1 : 0;
          break;
        case UNITS.A_2500:
        case UNITS.A_BIT:
        case UNITS.A_7000:
        case UNITS.A_4000:
          if (vm.currentUnit.type === UNITS.A_4000 || vm.currentUnit.type === UNITS.A_7000) {
            state = configNormallyOpen ? 1 : 0;
          }
          digitalSensorCard.stateSensor = state < 0 && state > 1 ? 2 : state;
          break;
      }

      digitalSensorCard.image =
        state < 0 || state > 1
          ? DIGITAL_SENSOR_IMAGE_ENUM.DS_ERROR
          : (configNormallyOpen && digitalSensorCard.stateSensor) ||
            (!configNormallyOpen && !digitalSensorCard.stateSensor)
          ? DIGITAL_SENSOR_IMAGE_ENUM.DS_CLOSED
          : DIGITAL_SENSOR_IMAGE_ENUM.DS_OPENED;
      digitalSensorCard.additionalText = configNormallyOpen ? 'NO' : 'NC';
      return digitalSensorCard;
    }
    function destroySubscriptions() {
      vm.gridData = [];
      vm.barChart = {};
      vm.lineChart = {};
      vm.isAnalogsDataEmtpy = true;
      vm.historyFacade.reset();
      vm.destroy$.next();
      vm.destroy$.complete();
    }
    $scope.$on('refresh-format', function (event, args) {
      vm.currentUnit = args.unit;
      vm.overlayEl.dismiss();
    });
    // $scope.$on('refresh', function (event, args) {
    //   if (args.unit) {
    //     angular.copy(args.unit, vm.currentUnit);
    //     $q.all([loadDigitals().then(), loadAnalogs().then()]).then(() => {
    //       if (vm.currentUnit.type === UNITS.A_4000) {
    //         checkConditioners();
    //       }
    //     });
    //     $scope.$emit('refreshed', { message: 'refreshed' });
    //   }
    // });

    $scope.$on('refresh-sensors', () => {
      $q.all([loadDigitals().then(), loadAnalogs().then()]).then(() => {
        if (vm.currentUnit.type === UNITS.A_4000) {
          checkConditioners();
        }
      });
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });

    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, args) => {
      vm.form = args;
    });
  }
})();
