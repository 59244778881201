import { UNITS } from '../utils/units.enum';
import { getContainer } from '@vegga/front-store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MapSensorStatus } from '../utils/enums/maps/maps-sensor-status.enum';
import { MapSensorType } from '../utils/enums/maps/maps-sensor-type.enum';
import { MapSensorIconType } from '../utils/enums/maps/maps-sensor-icon-type.enum';
import { UNITS_OF_MEASUREMENT_ENUM } from '@vegga/front-utils';
import { MapSensorFormatStatus } from '../utils/enums/maps/maps-sensor-format-status.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('sensorMapController', sensorMapController);

  sensorMapController.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    'mapsFactory',
    '$filter',
    '$q',
    'sensorsFactory',
    '$timeout',
    'calculatedsFactory',
  ];

  function sensorMapController(
    $rootScope,
    $scope,
    $state,
    mapsFactory,
    $filter,
    $q,
    sensorsFactory,
    $timeout,
    calculatedsFactory
  ) {
    const vm = $scope.$parent.vm;
    let currentMarker;
    let sensorBkp;
    vm.UNITS = UNITS;
    vm.destroySensorMarker$ = new Subject();

    activate();

    /*******************/

    function activate() {
      vm.mapsFacade = getContainer().resolve('mapsLegacyFacade');

      initSubscriptions();

      vm.actionView = 0; //Default view INFO, 1 = Manual, 2 = Edition area
      vm.addSensorStep = 0; //Selection sensor
      vm.iconType = 0; //Selection type sensor
      vm.equipoInfo = false;
      vm.addMarkerS = addMarker;
      vm.setMarkerIcon = setMarkerIcon;
      vm.returnType = returnType;
      vm.loadSelectionSensors = loadSelectionSensors;
      vm.prepareSensorInfo = prepareSensorInfo;
      vm.resetDropdownValues = resetDropdownValues;
      vm.history_type = 0;
      vm.sensorsInMap = [];
      vm.fetchedSensors = [];
    }

    function loadSensorLayer(markers) {
      if (vm?.map?.id) {
        vm.mapsFacade.loadMapsSensorsCalculated(vm.map.id);
        vm.mapsFacade.loadMapsSensorsAnalog(vm.map.id);
        vm.mapsFacade.loadMapsSensorsDigital(vm.map.id);
        vm.mapsFacade.loadMapsSensorsMeter(vm.map.id);

        vm.sensorsInMap = markers;
        vm.fetchedSensors = [];
      }
    }

    function initSubscriptions() {
      //Calculate sensors (DPV, ETO and Water Disp)
      vm.mapsFacade.mapsSensorsCalculated$.pipe(takeUntil(vm.destroySensorMarker$)).subscribe((sensors) => {
        sensors.forEach((sensor) => {
          const sensorDrawed = vm.sensorLayer.getLayers().find((s) => s.properties.id === sensor.id);
          if (sensorDrawed) {
            sensorDrawed.setIcon(createCalcIcon(sensor));
            sensorDrawed.properties.sensor = sensor;
            sensorDrawed.properties.sensorId = sensor.sensorId;
            sensorDrawed.properties.sensorType = sensor.type;
          } else {
            const marker = L.marker(new L.LatLng(sensor.latitude, sensor.longitude));
            marker.setIcon(createCalcIcon(sensor));
            marker.properties = {};
            marker.properties.id = sensor.id;
            marker.properties.sensor = sensor;
            marker.properties.sensorId = sensor.sensorId;
            marker.properties.sensorType = sensor.type;
            vm.sensorLayer.addLayer(marker);
          }
        });
        vm.sensorLayer.on({ click: showSensorInfo });
      });

      //Analog sensors
      vm.mapsFacade.mapsSensorsAnalog$.pipe(takeUntil(vm.destroySensorMarker$)).subscribe((sensors) => {
        sensors.forEach((sensor) => {
          const sensorDrawed = vm.sensorLayer.getLayers().find((s) => s.properties.id === sensor.id);
          if (sensorDrawed) {
            setIconType(sensorDrawed, sensor);
            sensorDrawed.properties.sensor = sensor;
            sensorDrawed.properties.sensorId = sensor.sensorId;
            sensorDrawed.properties.sensorType = sensor.type;
          } else {
            const marker = L.marker(new L.LatLng(sensor.latitude, sensor.longitude));
            setIconType(marker, sensor);
            marker.properties = {};
            marker.properties.id = sensor.id;
            marker.properties.sensor = sensor;
            marker.properties.sensorId = sensor.sensorId;
            marker.properties.sensorType = 0;
            vm.sensorLayer.addLayer(marker);
          }
        });
        vm.sensorLayer.on({ click: showSensorInfo });
      });

      //Digital sensors
      vm.mapsFacade.mapsSensorsDigital$.pipe(takeUntil(vm.destroySensorMarker$)).subscribe((sensors) => {
        sensors.forEach((sensor) => {
          const sensorDrawed = vm.sensorLayer.getLayers().find((s) => s.properties.id === sensor.id);
          if (sensorDrawed) {
            sensorDrawed.setIcon(createDigitalIcon(sensor));
            sensorDrawed.properties.deviceid = sensor.deviceId;
            sensorDrawed.properties.sensorId = sensor.sensorId;
            sensorDrawed.properties.sensor = sensor;
          } else {
            const marker = L.marker(new L.LatLng(sensor.latitude, sensor.longitude));
            marker.setIcon(createDigitalIcon(sensor));
            marker.properties = {};
            marker.properties.id = sensor.id;
            marker.properties.deviceid = sensor.deviceId;
            marker.properties.sensorType = 2;
            marker.properties.iconType = 0;
            marker.properties.sensorId = sensor.sensorId;
            marker.properties.sensor = sensor;
            vm.sensorLayer.addLayer(marker);
          }
        });
        vm.sensorLayer.on({ click: showSensorInfo });
      });

      //Meter sensors
      vm.mapsFacade.mapsSensorsMeter$.pipe(takeUntil(vm.destroySensorMarker$)).subscribe((sensors) => {
        sensors.forEach((sensor) => {
          const sensorDrawed = vm.sensorLayer.getLayers().find((s) => s.properties.id === sensor.id);
          if (sensorDrawed) {
            setIconType(sensorDrawed, sensor);
            sensorDrawed.properties.deviceid = sensor.deviceid;
            sensorDrawed.properties.sensorId = sensor.sensorId;
            sensorDrawed.properties.sensor = sensor;
          } else {
            const marker = L.marker(new L.LatLng(sensor.latitude, sensor.longitude));
            setIconType(marker, sensor);
            marker.properties = {};
            marker.properties.id = sensor.id;
            marker.properties.deviceid = sensor.deviceId;
            marker.properties.sensorType = 1;
            marker.properties.iconType = 0;
            marker.properties.sensorId = sensor.sensorId;
            marker.properties.sensor = sensor;
            vm.sensorLayer.addLayer(marker);
          }
        });
        vm.sensorLayer.on({ click: showSensorInfo });
      });
    }

    function setIconType(marker, sensor) {
      const unit = vm.units.filter((u) => u.id === sensor.deviceId);
      if (unit[0]?.type !== 2) {
        switch (sensor.iconType) {
          case MapSensorIconType.NUMERIC_ICON:
            marker.setIcon(createNumericIcon(sensor, unit));
            break;
          case MapSensorIconType.COLOR_ICON:
            marker.setIcon(createColorIcon(sensor));
            break;
          default:
            marker.setIcon(createNumericIcon(sensor, unit)); //if not defined, set numeric icon to show on the map
            break;
        }
      } else {
        marker.setIcon(createNumericIcon(sensor, unit));
      }
    }

    function setMarkerIcon() {
      const { layer, latlng } = vm.props;

      saveMarker(latlng).then(() => {
        vm.resetDropdownValues();
        layer.toggleEdit();
        vm.map.off('editable:drawing:commit');
        $scope.$emit('completedSave');
        vm.map.removeLayer(layer);
        loadSensorLayer(vm.sensorsInMap);
      });
    }

    function createCalcIcon(sensor) {
      const statusClassMap = {
        [MapSensorStatus.ERROR]: 'error',
        [MapSensorStatus.NOT_ACTIVE]: 'no-communicating',
        [MapSensorStatus.ACTIVE]: '',
      };

      const communicatingColor = sensor.status === MapSensorStatus.NOT_ACTIVE ? 'style="color:white;"' : '';

      const content = ` <div class="sensor-marker-icon ${statusClassMap[sensor.status]}">
          <div class="badge"><i class="glyphicon glyphicon-triangle-bottom"></i>
          <div class="text" ${communicatingColor}> ${
        sensor.status === MapSensorStatus.ACTIVE
          ? sensor.value + ' ' + sensor.units
          : sensor.status === MapSensorStatus.NOT_ACTIVE
          ? $filter('translate')('comm.em3')
          : 'Error'
      }  </div>
          <div class="badge-overlay">
          <div class="text-overlay" ${communicatingColor}> ${sensor.name ? sensor.name : '-'} </div>
          </div></div></div>`;
      return L.divIcon({
        className: 'custom-marker',
        html: content,
      });
    }

    function createDigitalIcon(sensor) {
      const statusClassMap = {
        [MapSensorStatus.ERROR]: 'error',
        [MapSensorStatus.NOT_ACTIVE]: sensor.formatStatus === MapSensorFormatStatus.OPEN ? 'nactive' : 'active',
        [MapSensorStatus.ACTIVE]: sensor.formatStatus === MapSensorFormatStatus.OPEN ? 'active' : 'nactive',
      };

      const labelClass = statusClassMap[sensor.status];

      const name = sensor.name === '' || sensor.name === null ? `Sensor ${sensor.sensorId}` : sensor.name;
      const content = `<div class="map-sensord-container"><div class="map-sensord-marker ${labelClass}"></div>
                      <div class="badge-overlay">
                      <div class="text-overlay"> ${name} </div>
                      </div></div>`;
      return L.divIcon({
        className: 'custom-marker',
        html: content,
      });
    }

    function createColorIcon(sensor) {
      let content = '';
      let color;
      switch (sensor.status) {
        case MapSensorStatus.NOT_ACTIVE:
        case MapSensorStatus.ACTIVE:
          if (validateCaption(sensor)) {
            color = getRangeColor(sensor.caption, sensor.value);
            const value = sensor.value ? `${sensor.value}  ${sensor.units}` : '-';
            const name = sensor.name === '' || sensor.name === null ? `Sensor ${sensor.sensorId}` : sensor.name;
            content = `<div class="map-sensord-container-color">
                          <div class="color-indicator" style="background-color: ${color}"></div>
                          <div class="badge-overlay">
                          <div class="text-overlay"> ${value} ${name}</div>
                          </div></div>`;
          } else {
            if (sensor.status === MapSensorStatus.ACTIVE || sensor.type === MapSensorType.COUNTER_SENSOR) {
              content = printDefaultContent(sensor);
            } else {
              content = printNoCommunicatingContent(sensor);
            }
          }
          return L.divIcon({
            className: 'custom-marker',
            html: content,
          });
        default:
          content = printErrorContent(sensor);
          return L.divIcon({
            className: 'custom-marker',
            html: content,
          });
      }
    }

    function printDefaultContent(sensor) {
      const name = sensor.name === '' || sensor.name === null ? `Sensor ${sensor.sensorId}` : sensor.name;
      return `<div class="sensor-marker-icon">
              <div class="badge"><i class="glyphicon glyphicon-triangle-bottom"></i>
              <div class="text">${sensor.value} ${sensor.units}</div>
              <div class="badge-overlay">
              <div class="text-overlay">${name}</div>`;
    }

    function printErrorContent(sensor) {
      const name = sensor.name === '' || sensor.name === null ? `Sensor ${sensor.sensorId}` : sensor.name;
      return `<div class="sensor-marker-icon error">
              <div class="badge"><i class="glyphicon glyphicon-triangle-bottom"></i>
              <div class="text"> Error </div>
              <div class="badge-overlay">
              <div class="text-overlay"> ${name} </div>`;
    }

    function printNoCommunicatingContent(sensor) {
      const name = sensor.name === '' || sensor.name === null ? `Sensor ${sensor.sensorId}` : sensor.name;
      return `<div class="sensor-marker-icon no-communicating">
              <div class="badge"><i class="glyphicon glyphicon-triangle-bottom"></i>
              <div class="text" style="color:white;">${$filter('translate')('comm.em3')}</div>
              <div class="badge-overlay">
              <div class="text-overlay" style="color:white;"> ${name} </div>`;
    }

    function createNumericIcon(sensor, unit) {
      let content;
      switch (sensor.type) {
        case MapSensorType.ANALOG_SENSOR:
          switch (sensor.status) {
            case MapSensorStatus.NOT_ACTIVE:
              content = printNoCommunicatingContent(sensor);
              return L.divIcon({
                className: 'custom-marker',
                html: content,
              });
            case MapSensorStatus.ACTIVE:
              content = printDefaultContent(sensor);
              return L.divIcon({
                className: 'custom-marker',
                html: content,
              });
            default:
              content = printErrorContent(sensor);
              return L.divIcon({
                className: 'custom-marker',
                html: content,
              });
          }
        case MapSensorType.COUNTER_SENSOR:
          if (unit[0] && unit[0].type === UNITS.A_4500) {
            switch (sensor.status) {
              case MapSensorStatus.NOT_ACTIVE:
              case MapSensorStatus.ACTIVE:
                sensor.units = UNITS_OF_MEASUREMENT_ENUM[sensor.units]?.SYMBOL ?? vm.sensor.units;
                content = printDefaultContent(sensor);
                return L.divIcon({
                  className: 'custom-marker',
                  html: content,
                });
              default:
                content = printErrorContent(sensor);
                return L.divIcon({
                  className: 'custom-marker',
                  html: content,
                });
            }
          }
          sensor.meter = true;
          if (sensor.status) {
            switch (sensor.status) {
              case MapSensorStatus.NOT_ACTIVE:
              case MapSensorStatus.ACTIVE:
                if (unit[0] && unit[0].type !== UNITS.A_4000) {
                  sensor.units = UNITS_OF_MEASUREMENT_ENUM[sensor.units]?.SYMBOL ?? vm.sensor.units;
                  content = printDefaultContent(sensor);
                  return L.divIcon({
                    className: 'custom-marker',
                    html: content,
                  });
                } else {
                  //A4000
                  const name = sensor.name === '' || sensor.name === null ? `Sensor ${sensor.sensorId}` : sensor.name;
                  content = `<div class="sensor-marker-icon">
                              <div class="badge"><i class="glyphicon glyphicon-triangle-bottom"></i>
                              <div class="text"> ${
                                sensor.sensorActive4 === MapSensorStatus.ACTIVE ? sensor.value : '-'
                              } </div>
                              <div class="badge-overlay">
                              <div class="text-overlay"> ${name} </div>
                              </div></div></div>`;
                  return L.divIcon({
                    className: 'custom-marker',
                    html: content,
                  });
                }
              default:
                content = printErrorContent(sensor);
                return L.divIcon({
                  className: 'custom-marker',
                  html: content,
                });
            }
          } else {
            let name = '';
            if (unit[0] && unit[0].type === UNITS.A_7000) {
              name = $filter('translate')('sensors.IDS7_' + sensor.sensorId);
            } else {
              name = $filter('translate')('sensors.IDS_' + sensor.sensorId);
            }
            sensor.units = UNITS_OF_MEASUREMENT_ENUM[sensor.units]?.SYMBOL ?? vm.sensor.units;
            content = ` <div class="sensor-marker-icon">
                          <div class="badge"><i class="glyphicon glyphicon-triangle-bottom"></i>
                          <div class="text"> ${sensor.value != null ? `${sensor.value} ${sensor.units}` : `-`} </div>
                          <div class="badge-overlay">
                          <div class="text-overlay"> ${name}</div>
                          </div></div></div>`;
            return L.divIcon({
              className: 'custom-marker',
              html: content,
            });
          }
      }
    }

    function getRangeColor(caption, value) {
      const rangeColors = {
        3: getRangeColorFor3Levels,
        5: getRangeColorFor5Levels,
      };

      return rangeColors[caption.numLvl](caption, value);
    }

    function getRangeColorFor3Levels(caption, value) {
      if (value > caption.range1from && value <= caption.range1to) {
        return caption.color1;
      }
      if (value > caption.range2from && value <= caption.range2to) {
        return caption.color2;
      }
      if (value > caption.range3from && value <= caption.range3to) {
        return caption.color3;
      }
      if (value <= caption.range1from) {
        return caption.color1;
      }
      if (value >= caption.range3to) {
        return caption.color3;
      }
    }

    function getRangeColorFor5Levels(caption, value) {
      if (value > caption.range1from && value < caption.range1to) {
        return caption.color1;
      }
      if (value > caption.range2from && value < caption.range2to) {
        return caption.color2;
      }
      if (value > caption.range3from && value < caption.range3to) {
        return caption.color3;
      }
      if (value > caption.range4from && value < caption.range4to) {
        return caption.color4;
      }
      if (value > caption.range5from && value < caption.range5to) {
        return caption.color5;
      }
      if (value < caption.range1from) {
        return caption.color1;
      }
      if (value > caption.range5to) {
        return caption.color5;
      }
    }

    function validateCaption(sensor) {
      if (sensor.caption === null) {
        sensor.caption_valid = false;
        return sensor.caption_valid;
      } else {
        switch (sensor.caption.numLvl) {
          case 3:
            if (validateRange3Levels(sensor.caption) && validateColor3Levels(sensor.caption)) {
              sensor.caption_valid = true;
              return sensor.caption_valid;
            }
            break;
          case 5:
            if (validateRange5Levels(sensor.caption) && validateColor5Levels(sensor.caption)) {
              sensor.caption_valid = true;
              return sensor.caption_valid;
            }
            break;
        }
        sensor.caption_valid = false;
        return sensor.caption_valid;
      }
    }

    function validateRange3Levels(caption) {
      return (
        caption.range1from !== null &&
        caption.range2from !== null &&
        caption.range3from !== null &&
        caption.range1to !== null &&
        caption.range2to !== null &&
        caption.range3to !== null
      );
    }

    function validateColor3Levels(caption) {
      return caption.color1 !== null && caption.color2 !== null && caption.color3 !== null;
    }

    function validateRange5Levels(caption) {
      return (
        caption.range1from !== null &&
        caption.range2from !== null &&
        caption.range3from !== null &&
        caption.range4from !== null &&
        caption.range5from !== null &&
        caption.range1to !== null &&
        caption.range2to !== null &&
        caption.range3to !== null &&
        caption.range4to !== null &&
        caption.range5to !== null
      );
    }

    function validateColor5Levels(caption) {
      return (
        caption.color1 !== null &&
        caption.color2 !== null &&
        caption.color3 !== null &&
        caption.color4 !== null &&
        caption.color5 !== null
      );
    }

    function showSensorInfo(e) {
      vm.history = false;
      if (e.layer.properties.sensor.deviceId !== undefined && e.layer.properties.sensor.deviceId !== null) {
        const unit = _.filter(vm.units, { id: Number(e.layer.properties.sensor.deviceId) });
        if (!_.isEmpty(unit)) {
          vm.currentUnit = unit[0];
          vm.sensor = e.layer.properties.sensor;

          if (vm.currentUnit.type === vm.UNITS.A_4000) {
            if (sensorBkp) {
              vm.sensor.sensorId = sensorBkp.pk.id;
            } else if (e.layer.properties.sensorId) {
              vm.sensor.sensorId = e.layer.properties.sensorId;
            }
          }
          currentMarker = e.layer;
          $timeout(() => {
            vm.map.invalidateSize();
            const overlay = document.getElementById('mapToolsOverlay');
            const overlayWidth = overlay.shadowRoot.querySelector('.vegga-overlay__container').offsetWidth / 2;

            const offset = [-Math.abs(overlayWidth), 0]; // Desplazamiento en píxeles
            const targetPoint = vm.map.project(e.latlng, vm.map.getZoom()).subtract(offset);
            const targetLatLng = vm.map.unproject(targetPoint, vm.map.getZoom());
            vm.map.setView(targetLatLng, vm.map.getZoom());
          }, 200);
          $state.go(
            'maps.sensor',
            { unit: vm.currentUnit, sensor: vm.sensor, map: vm.map, fetchedSensors: vm.fetchedSensors },
            { reload: 'maps.sensor' }
          );
        }
      } else {
        vm.sensor = e.layer.properties.sensor;
        currentMarker = e.layer;

        $timeout(() => {
          vm.map.invalidateSize();
          const overlay = document.getElementById('mapToolsOverlay');
          const overlayWidth = overlay.shadowRoot.querySelector('.vegga-overlay__container').offsetWidth / 2;

          const offset = [-Math.abs(overlayWidth), 0]; // Desplazamiento en píxeles
          const targetPoint = vm.map.project(e.latlng, vm.map.getZoom()).subtract(offset);
          const targetLatLng = vm.map.unproject(targetPoint, vm.map.getZoom());
          vm.map.setView(targetLatLng, vm.map.getZoom());
        }, 200);
        $state.go(
          'maps.sensor',
          {
            sensor: vm.sensor,
            map: vm.map,
            type: e.layer.properties.sensorType,
            unit: null,
            fetchedSensors: vm.fetchedSensors,
          },
          { reload: 'maps.sensor' }
        );
      }

      vm.overlay.show();
      $scope.$emit('editSectionChangeSensor', {
        sensor: vm.sensor,
      });
    }

    function prepareSensorInfo(props) {
      vm.sensor = vm.sensor || props;
      if (props !== undefined) {
        vm.sensor.type = Number(props.sensorType);
      }
    }

    function saveMarker(latLng) {
      let params = {};
      params.lat = latLng.lat;
      params.lng = latLng.lng;
      params.mapId = vm.map.id;
      if (vm.sensorType < 3) {
        params.deviceId = vm.unit.id;
      }
      params.userId = $rootScope.user.id;
      params.type = 2;
      params.prop1 = vm.sensorType; //analog,meter,digital
      if (vm.sensorType < 3) {
        if (vm.sensorType == 1) {
          if (vm.sensor.prop2 == 0) {
            vm.iconType = 0;
          } else if (vm.sensor.prop2 == 1) {
            vm.iconType = 1;
          } else if (vm.unit.type === 2) {
            vm.iconType = vm.sensor.prop2;
          }
        }
        params.prop2 = vm.iconType; //Number,color,NumbAndColor
      }
      params.prop3 = vm.sensor.pk.id;
      return mapsFactory.saveMarker(vm.map.id, params);
    }

    function addMarker() {
      vm.setMarkerIcon();
      vm.addSensorStep = 0;
    }

    function initAddMarker() {
      vm.filter.comp.sensor = true;
      vm.filter.comp.active = true;
    }

    function closeSensorInfo() {
      $timeout(() => {
        vm.map.invalidateSize();
      }, 200);
    }

    function returnType(type) {
      switch (type) {
        case 2:
          return 'Agrónic 4000';
        case 3:
          return 'Agrónic 2500';
        case 4:
          return 'Agrónic BIT';
        case 5:
          return 'Agrónic 7000';
        case 6:
          return 'Agrónic 5500';
      }
    }

    function deleteSensorMarker() {
      mapsFactory.deleteMarker(vm.map.id, currentMarker.properties.id).then(() => {
        vm.sensorInfo = false;
        vm.sensorLayer.removeLayer(currentMarker);
      });
    }

    function loadSelectionSensors() {
      vm.sensor = null;
      vm.sensors = [];
      vm.areSensorsLoading = true;
      switch (vm.sensorType) {
        case 0: //Analog
          if (vm.unit !== undefined && vm.unit !== null) {
            sensorsFactory.analogsactive(vm.unit.id, 'all').then((resp) => {
              vm.sensors = resp.plain();
              vm.areSensorsLoading = false;
            });
          } else {
            vm.areSensorsLoading = false;
          }
          break;
        case 1: //Meter
          if (vm.unit !== undefined && vm.unit !== null) {
            if (vm.unit.type === 2) {
              //A4000
              vm.sensors = [
                { pk: { id: 11, deviceId: vm.unit.id }, name: $filter('translate')('sensors.IDS_11'), prop2: 1 },
                { pk: { id: 12, deviceId: vm.unit.id }, name: $filter('translate')('sensors.IDS_12'), prop2: 2 },
                { pk: { id: 13, deviceId: vm.unit.id }, name: $filter('translate')('sensors.IDS_13'), prop2: 3 },
                { pk: { id: 14, deviceId: vm.unit.id }, name: $filter('translate')('sensors.IDS_14'), prop2: 4 },
                { pk: { id: 23, deviceId: vm.unit.id }, name: $filter('translate')('sensors.IDS_23'), prop2: 1 },
                { pk: { id: 24, deviceId: vm.unit.id }, name: $filter('translate')('sensors.IDS_24'), prop2: 2 },
                { pk: { id: 25, deviceId: vm.unit.id }, name: $filter('translate')('sensors.IDS_25'), prop2: 3 },
                { pk: { id: 26, deviceId: vm.unit.id }, name: $filter('translate')('sensors.IDS_26'), prop2: 4 },
              ];
              vm.areSensorsLoading = false;
            } else {
              sensorsFactory.meters(vm.unit.id).then((resp) => {
                vm.sensors = resp.plain();
                vm.areSensorsLoading = false;
              });
            }
          } else {
            vm.areSensorsLoading = false;
          }
          break;
        case 2: //Digital
          if (vm.unit !== undefined && vm.unit !== null) {
            if (vm.unit.type === 2) {
              //A4000
              sensorsFactory.digitalsPage(vm.unit.id, { limit: 50, page: 1 }).then(function (data) {
                const sensors = data.content;
                const counters = extractCounters(sensors);
                vm.sensors = _.xor(sensors, counters);
                vm.areSensorsLoading = false;
              });
            } else {
              sensorsFactory.digitalsPage(vm.unit.id, { limit: 50, page: 1 }).then(function (data) {
                vm.sensors = data.content;
                vm.areSensorsLoading = false;
              });
            }
          } else {
            vm.areSensorsLoading = false;
          }
          break;
        case 3: //Sensor DPV
          calculatedsFactory.getDPV($rootScope.user.id).then((result) => {
            if (result.plain()) vm.sensors = result.plain();
            vm.areSensorsLoading = false;
          });
          break;
        case 4: //Sensor ETo
          calculatedsFactory.getETO($rootScope.user.id).then((result) => {
            if (result.plain()) vm.sensors = result.plain();
            vm.areSensorsLoading = false;
          });
          break;
        case 5: //Sensor Water Disp
          calculatedsFactory.waterDisp($rootScope.user.id).then((result) => {
            if (result.plain()) vm.sensors = result.plain();
            vm.areSensorsLoading = false;
          });
          break;
      }
    }

    function resetDropdownValues() {
      vm.sensors = [];
      vm.sensorType = null;
    }

    function extractCounters(sensors) {
      return _.filter(sensors, (o) => {
        return Number(o.pk.id) >= 11 && Number(o.pk.id) <= 35;
      });
    }

    $scope.$on('deleteSensor', () => {
      deleteSensorMarker();
      closeSensorInfo();
    });

    $scope.$on('closeSensor', () => {
      closeSensorInfo();
    });

    $scope.$on('addSensorMarker', (e, args) => {
      vm.props = args;
      initAddMarker();
    });

    $scope.$on('loadSensorLayer', () => {
      if (vm.filter.comp.active && vm.filter.comp.sensors) loadSensorLayer(vm.sensors);
    });

    $scope.$on('$destroy', function () {
      vm.destroySensorMarker$.next();
      vm.destroySensorMarker$.complete();
    });
  }
})();
