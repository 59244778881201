import { Subject, switchMap, takeUntil, tap } from 'rxjs';
import { getContainer } from '@vegga/front-store';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('filtersHeaderController', filtersHeaderController);

  filtersHeaderController.$inject = [
    '$scope',
    '$state',
    '$stateParams',
    'filterFactory',
    '$confirm',
    '$filter',
    'manualFactory',
    'unitFactory',
    '$rootScope',
  ];

  function filtersHeaderController(
    $scope,
    $state,
    $stateParams,
    filterFactory,
    $confirm,
    $filter,
    manualFactory,
    unitFactory,
    $rootScope
  ) {
    var vm = this;
    vm.save;
    vm.cancel;
    vm.filtersHeader;
    vm.activeAll;
    vm.headerId;
    vm.header1;
    vm.header2;
    vm.header3;
    vm.header4;
    vm.cancel;
    vm.save;
    vm.activeAll = true;
    vm.currentUnit = $state.params.unit;
    vm.showHeaders = showHeaders;
    vm.showOverlay = showOverlay;
    vm.changeState = changeState;
    vm.getNumberArray = getNumberArray;
    vm.cancel = cancel;
    vm.save = save;
    vm.exitMalfunction = exitMalfunction;
    vm.stopCleaning = stopCleaning;
    vm.startCleaning = startCleaning;
    vm.outOfService = outOfService;
    vm.exitOutOfService = exitOutOfService;
    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$
        .pipe(
          tap((currentUnit) => {
            if (!currentUnit) {
              $state.go('units');
            }
            loadData(currentUnit);
          }),
          switchMap(() => filterFactory.getFiltersResponse()),
          takeUntil(vm.destroy$)
        )
        .subscribe((filters) => {
          // set null xStatus to 0
          filters.forEach((filter) => (filter.xStatus = filter.xStatus || 0));
          if (!vm.currentUnit.inoptions.optionPlus || vm.currentUnit.version < +$rootScope.env.A4500_VERSION) {
            vm.filtersHeader = filters.filter((f) => +f.pk.headerId === 1 || +f.pk.id === 1);
            vm.headerId = 1;
          } else {
            vm.filtersHeader = filters;
            vm.headerId = vm.headerId || +$stateParams.headerId;
          }
          vm.enabledHeaders = vm.filtersHeader.reduce((headersMap, filter) => {
            headersMap[filter.pk.headerId] = [...(headersMap[filter.pk.headerId] || []), filter];
            return headersMap;
          }, {});
          showManualButtons();
        });

      vm.currentState = $state.includes;

      if ($state.current.name === 'filters45.config.header') {
        vm.showHeaders(`header${$stateParams.headerId || vm.headerId}`);
        changeHeader();
        showOverlay('filters45.config.header');
      }
    }

    function loadData(unit) {
      vm.currentUnit = unit;
      const storedFilters = filterFactory.getFiltersValue();
      if (storedFilters) {
        vm.filtersHeader = storedFilters;
        showManualButtons();
      } else {
        loadFilters();
      }
    }

    function changeHeader() {
      if (vm.header1) {
        vm.headerId = 1;
      } else if (vm.header2) {
        vm.headerId = 2;
      } else if (vm.header3) {
        vm.headerId = 3;
      } else if (vm.header4) {
        vm.headerId = 4;
      } else {
        vm.headerId = 1;
        vm.activeAll = false;
        vm.header1 = true;
      }
    }

    function loadFilters() {
      filterFactory.getFilters(vm.currentUnit.id);
    }

    function showOverlay(state) {
      if (!$state.current.params || (!$state.current.params.headerId && !$stateParams.headerId)) {
        changeHeader();
      }

      $state.go(state, { headerId: vm.headerId });

      vm.overlayEl = document.querySelector('#filter-header-overlay');
      vm.overlayEl.show();
    }

    function showHeaders(value) {
      if (value !== 'all') {
        vm.headerId = +value.match(/\d+/)[0];
      } else {
        vm.headerId = null;
      }
      switch (value) {
        case 'all':
          vm.activeAll = true;
          vm.header1 = false;
          vm.header2 = false;
          vm.header3 = false;
          vm.header4 = false;
          break;
        case 'header1':
          vm.activeAll = false;
          vm.header1 = true;
          vm.header2 = false;
          vm.header3 = false;
          vm.header4 = false;
          break;
        case 'header2':
          vm.activeAll = false;
          vm.header1 = false;
          vm.header2 = true;
          vm.header3 = false;
          vm.header4 = false;
          break;
        case 'header3':
          vm.activeAll = false;
          vm.header1 = false;
          vm.header2 = false;
          vm.header3 = true;
          vm.header4 = false;
          break;
        case 'header4':
          vm.activeAll = false;
          vm.header1 = false;
          vm.header2 = false;
          vm.header3 = false;
          vm.header4 = true;
          break;
      }
    }

    function showManualButtons() {
      _.forEach(vm.filtersHeader, (filter) => {
        switch (filter.xStatus) {
          case 0:
            filter.btnStartFilter = true;
            filter.btnStopFilter = false;
            filter.btnFailureFilter = false;
            filter.btnOutServiceFilter = true;
            filter.btnExitOutServiceFilter = false;
            break;
          case 1:
          case 2:
          case 4:
          case 5:
          case 6:
            filter.btnStartFilter = false;
            filter.btnStopFilter = true;
            filter.btnFailureFilter = false;
            filter.btnOutServiceFilter = true;
            filter.btnExitOutServiceFilter = false;
            break;
          case 3:
            filter.btnStartFilter = false;
            filter.btnStopFilter = false;
            filter.btnFailureFilter = true;
            filter.btnOutServiceFilter = true;
            filter.btnExitOutServiceFilter = false;
            break;
          case 7:
            filter.btnStartFilter = false;
            filter.btnStopFilter = false;
            filter.btnFailureFilter = true;
            filter.btnOutServiceFilter = false;
            filter.btnExitOutServiceFilter = true;
            break;
          default:
            filter.btnStartFilter = false;
            filter.btnStopFilter = false;
            filter.btnFailureFilter = false;
            filter.btnOutServiceFilter = false;
            filter.btnExitOutServiceFilter = false;
            break;
        }

        filter.isConfigured = [
          filter.btnStartFilter,
          filter.btnStopFilter,
          filter.btnFailureFilter,
          filter.btnOutServiceFilter,
          filter.btnExitOutServiceFilter,
        ].some((flag) => flag);
      });
    }

    function startCleaning({ pk: { id, headerId } }) {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = 22;
        params.parameter1 = +headerId;
        params.parameter2 = 1;
        params.parameter3 = +id;
        manualFactory.genericManualAction(vm.currentUnit.id, params).then(() => {});

        modalInstance.result.then(() => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function stopCleaning({ pk: { id, headerId } }) {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = 22;
        params.parameter1 = +headerId;
        params.parameter2 = 0;
        params.parameter3 = +id;
        manualFactory.genericManualAction(vm.currentUnit.id, params).then(() => {});

        modalInstance.result.then(() => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function outOfService({ pk: { id, headerId } }) {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = 15;
        params.parameter1 = +headerId;
        params.parameter2 = 1;
        params.parameter3 = +id;
        manualFactory.genericManualAction(vm.currentUnit.id, params).then(() => {});

        modalInstance.result.then(() => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function exitOutOfService({ pk: { id, headerId } }) {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = 15;
        params.parameter1 = +headerId;
        params.parameter2 = 0;
        params.parameter3 = +id;
        manualFactory.genericManualAction(vm.currentUnit.id, params).then(() => {});

        modalInstance.result.then(() => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    function exitMalfunction({ pk: { id, headerId } }) {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        var params = {};
        params.type = vm.currentUnit.type;
        params.deviceId = vm.currentUnit.id;
        params.action = 23;
        params.parameter1 = +headerId;
        // TODO: Find which param corresponds ASK BACKEND
        // params.parameter2 = null;
        params.parameter3 = +id;
        manualFactory.genericManualAction(vm.currentUnit.id, params).then(() => {});

        modalInstance.result.then(() => {
          $scope.$emit('reload', { message: 'reload' });
        });
      });
    }

    //INI funcions copiades de filtres, quan es faci la consulta shan de revisar
    /* function checkState(){
        _.forEach(vm.filtersHeader, filter =>{           
            if(filter.xStatus){
                filter.state = 'cleaning';
            }else if(vm.currentUnit.ram && vm.currentUnit.ram.filterMalfunction && filter.xActivation){
                filter.state = 'malfunction';
            }else{
                var engines = _.pickBy(filter, function(value, key) {
                    return _.startsWith(key, 'engine');
                });
                _.forEach(engines, (val,key)  => {
                        if(val){
                            var index = key.substr(key.length - 1); 
                            if(vm.currentUnit.engines){
                                var engine = vm.currentUnit.engines[index-1];
                                if(engine.xState){
                                    filter.state = 'filtering';
                                }
                            }  
                        }

                });
                if(filter.state !== 'filtering'){
                    filter.state = 'noactive';
                }
            }            
        });
    } */

    function getNumberArray(num) {
      return new Array(num);
    }
    //FI

    /**
     * Funcions que executen el broadcast als child controllers d'Edició i Configuració
     */
    function cancel($event) {
      if (vm.form && vm.form.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({ text: $filter('translate')('programs.edit.cancelq') }).then(() => {
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          document.querySelector('#filter-header-overlay').dismiss();
          $state.go('filters45');
        });
      } else {
        document.querySelector('#filter-header-overlay').dismiss();
        $state.go('filters45');

        //(typeof $event !== 'undefined') ? $state.go('a25programs',{program:vm.program,unit:vm.currentUnit}) : $state.go('a25programs.detail',{program:vm.program,id:vm.program.pk.id});
      }
    }
    function save() {
      $scope.$broadcast('formSubmit');
    }

    function changeState(location, { headerId }) {
      if ((vm.form && vm.form.$dirty) || vm.hasViewErrors) {
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          changeHeader();
          $scope.$broadcast('formCancel'); //Emetem cancelació de canvis
          vm.form.$setPristine(); //Actualitzem estat del formulari a inicial
          $state.go(location, { headerId }); //Canviem d'estat
          setTimeout(() => {
            vm.overlayEl.show();
          }, 100);
        });
      } else {
        changeHeader();
        $state.go(location, { headerId });
      }
    }

    $scope.$on('refresh-format', function (event, args) {
      vm.currentUnit = args.unit;
      vm.overlayEl.dismiss();
    });
    $scope.$on('refresh', function () {
      /* if(args.unit){
            angular.copy(args.unit, vm.currentUnit)
            $q.all([loadDigitals().then(),loadAnalogs().then(), loadActiveLogics().then()]).then(o =>{
                if(vm.currentUnit.type === UNITS.A_4000){
                    checkConditioners();
                }  
            });
            $scope.$emit('refreshed',{message:'refreshed'});
        } */
    });

    $scope.$on('refresh', function (event, args) {
      if (args.unit) {
        loadFilters();
        $scope.$emit('refreshed', { message: 'refreshed' });
      }
    });

    /**
     * Event listener per gestionar l'estat del formulari
     */
    $scope.$on('formUpdated', (e, form) => {
      vm.form = form;
    });

    $scope.$on('headerGeneralFiltersUpdate', (e, args) => {
      vm.currentUnit.installer.headerGeneralFilters = args;
      console.log(vm.currentUnit.installer.headerGeneralFilters, args);
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
      filterFactory.clearFiltersResponse();
    });

    $scope.$on('hasViewErrors', (e, errors) => {
      vm.hasViewErrors = errors;
    });
  }
})();
