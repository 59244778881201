import { navigateToUrl, registerApplication, start } from 'single-spa';
import { constructApplications, constructLayoutEngine, constructRoutes } from 'single-spa-layout';
import microfrontendLayout from './layout/microfrontend-layout.html';
//@ts-ignore: In-browser module import
import { AuthStore, OrganizationStore, getContainer } from '@vegga/front-store';

import 'bootstrap/scss/bootstrap-grid.scss';
import './assets/fonts.css';
import './styles/main.scss';
//@ts-ignore: In-browser module import
import { isApplicationUnderMaintenance, isLoggedIn, setMicroVersion } from '@vegga/front-utils';
import {
  combineLatest,
  debounce,
  delay,
  filter,
  fromEvent,
  interval,
  map,
  merge,
  of,
  startWith,
  Subject,
  switchMap,
  take,
  tap,
  timer,
} from 'rxjs';
import sidebarHandler from './utils/sidebarHandler';

// perform application bootstrap actions
bootstrap();

function bootstrap(): void {
  checkApplicationMaintenanceState();
  useFakeDeviceHeader();
}

function setMicroHeight(microStatus?: 'MOUNTED' | 'UNMOUNTED') {
  const microContentEl = document.querySelector('.vegga__container').lastChild;
  
  if(microStatus === 'MOUNTED') {
    (microContentEl as HTMLDivElement).style.setProperty('--vegga-padding-content-view', '0');
  }
}

function setCoreConfig(): void {
  const layoutData = {
    props: {
      toast: document.querySelector('vegga-toast-manager'),
      sidebarState$: sidebarHandler.sidebarState$
    },
    loaders: {},
  };

  const routes = constructRoutes(microfrontendLayout, layoutData);
  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      return System.import(name);
    },
  });
  const layoutEngine = constructLayoutEngine({ routes, applications });
  applications.forEach(registerApplication);
  layoutEngine.activate();
  start();
  setMicroVersion();
}

function checkApplicationMaintenanceState(): void {
  let appUnderMaintenance;
  isApplicationUnderMaintenance()
    .pipe(
      switchMap((data: string) => {
        setCoreConfig();

        if (data !== null) {
          appUnderMaintenance = JSON.parse(data);
        }
        return combineLatest([
          fromEvent(window, 'single-spa:before-routing-event').pipe(
            tap((evt: CustomEvent) => {
              if (appUnderMaintenance) {
                navigateToUrl('/maintenance');
                return;
              }
              const authFacade = getContainer().resolve('authFacade');
              if (!isLoggedIn() && !evt.detail.newUrl.includes('/authentication')) {
                authFacade.setCustomAfterLoginUrl(evt.detail.newUrl);
                navigateToUrl('/authentication/sign-in');
                return;
              }
              authFacade.setUserPermissions();
              const path = evt.detail.newUrl.split(location.origin)[1];
              if (path === '/') {
                navigateToUrl('/home');
              } else {
                navigateToUrl(path);
              }
            }),
            switchMap((evt: CustomEvent) => {
              if (isLoggedIn()) {
                const authStore = AuthStore.getInstance();
                return authStore.get$();
              }
              if (!evt.detail.newUrl.includes('/authentication')) {
                navigateToUrl('/authentication/sign-in');
              }
              return of(null);
            }),
            tap((res) => {
              const organizationStore = OrganizationStore.getInstance();
              const authStore = AuthStore.getInstance();
              if (res) {
                organizationStore.storeOrganization(authStore.getDefaultOrganization());
              }
            })
          ),
          fromEvent(window, 'single-spa:routing-event').pipe(
            tap((ev) => {
              document.querySelector('.vegga__container') && setMicroHeight();
            })
          ),
          fromEvent(window, 'load').pipe(
            tap(() => {
              document.querySelector('.vegga__container') && setMicroHeight();
            })
          ),
          fromEvent(window, 'resize').pipe(
            tap(() => {
              document.querySelector('.vegga__container') && setMicroHeight();
            }),
            startWith([])
          ),
        ]);
      }),
      switchMap(() => interval(600000)),
      switchMap(() => isApplicationUnderMaintenance())
    )
    .subscribe({
      next: (data) => (appUnderMaintenance = data),
    });
}

function useFakeDeviceHeader(): void {
  let header: HTMLElement;
  let card: HTMLElement;
  let navigation: HTMLElement;
  let fakeHeader: HTMLElement;
  let fakeCard: HTMLElement;
  let fakeNavigation: HTMLElement;
  let fakeHeaderContainer: HTMLElement;
  const veggaFacade = getContainer().resolve('veggaFacade');
  let isIrrigationControl: boolean;
  let isChangingAppStatus: boolean;
  const devicesFacade = getContainer().resolve('devicesFacade');

  const setFakeHeaderContainer = (condition = true) => {
    card = document.querySelector('#deviceCard') as HTMLElement;
    header = document.querySelector('vegga-layout-header') as HTMLElement;
    navigation = document.querySelector('vegga-navigation') as HTMLElement;

    if (condition && card) {
      fakeHeaderContainer = createFakeHeaderContainer(header, navigation);
      fakeHeader = header.cloneNode(true) as HTMLElement;
      fakeCard = card.cloneNode(true) as HTMLElement;
      fakeCard.id = undefined;
      fakeNavigation = navigation.cloneNode(true) as HTMLElement;



      (fakeNavigation as any).optionSelected = Array.from(navigation.children).findIndex(child => child.classList.contains('vegga-navigation__option--active'));

      setNavigationListener();

      const headerBackBtn = Array.from(fakeHeader.children).find((el) => el.classList.contains('icon'));
      headerBackBtn.removeEventListener('veggaButtonClick', (headerBackBtn as any).veggaButtonClick);
      headerBackBtn.addEventListener('veggaButtonClick', () => {
        navigateToUrl('/irrigation-control/units');
        fakeHeaderContainer.remove();
        fakeHeaderContainer = null;
      });

      fakeHeaderContainer.style.display = 'none';
      fakeHeaderContainer.append(fakeHeader, fakeCard, fakeNavigation);
      document.body.append(fakeHeaderContainer);
    }
  };

  // preloads fake container header for the first time
  // avoiding popping when changing between micros for first time
  fromEvent(window, 'single-spa:app-change')
    .pipe(
      take(1),
      switchMap(() =>
        veggaFacade.isLoading$.pipe(
          delay(500),
          filter((isLoading) => !isLoading)
        )
      )
    ).subscribe(() => {
      if (!fakeHeaderContainer && devicesFacade.currentDevice && isIrrigationControl) {
        setFakeHeaderContainer();
      }
    });

  fromEvent(window, 'single-spa:routing-event')
    .pipe(
      take(1),
      delay(2000),
      switchMap(() => {
        return fromEvent(window, 'single-spa:before-routing-event').pipe(
          tap((ev) => {
            const { appsByNewStatus, newUrl } = (ev as CustomEvent).detail;
            isChangingAppStatus = appsByNewStatus.MOUNTED.length && appsByNewStatus.NOT_MOUNTED.length;
            const isOverlay = ['edit', 'detail', 'config'].some((key) => newUrl.includes(key));

            const isAllowedDevice =
              devicesFacade.currentDevice &&
              (devicesFacade.currentDevice.type !== 4 || devicesFacade.currentDevice.type !== 5 || devicesFacade.currentDevice.type !== 6);
            if ((!isAllowedDevice || isOverlay) && fakeHeaderContainer) {
              fakeHeaderContainer.style.display = 'none';
            }
          }),
          filter((ev) => {
            const { newUrl, appsByNewStatus } = (ev as CustomEvent).detail;
            isIrrigationControl = (newUrl.includes('unit') && !newUrl.includes('units')) || newUrl.includes('devices');

            if (!isIrrigationControl && fakeHeaderContainer) {
              fakeHeaderContainer.remove();
              fakeHeaderContainer = null;
            }

            return (
              (isIrrigationControl || newUrl.includes('devices')) &&
              // ensure that is changing between microfrontend apps before render fake card
              isChangingAppStatus
            );
          })
        );
      }),

      tap((ev) => {
        setFakeHeaderContainer(!fakeHeaderContainer);
        fakeHeaderContainer.style.display = 'block';
      }),
      switchMap(() => fromEvent(window, 'single-spa:routing-event')),
      // wait until facade is loaded to show real header
      switchMap(() => getContainer().resolve('veggaFacade').isLoading$.pipe(delay(2000)))
    )
    .subscribe((isLoading) => {
      if (!isLoading && fakeHeaderContainer) {
        fakeHeaderContainer.style.display = 'none';
      }
    });

  // adapt fakeHeaderContainer to new window width on resize
  fromEvent(window, 'resize')
    .pipe(
      filter(() => isIrrigationControl),
      debounce(() => timer(200))
    )
    .subscribe(() => {
      if (isIrrigationControl) {
        fakeHeaderContainer && fakeHeaderContainer.remove();
        setFakeHeaderContainer();
      }
    });

  const setNavigationListener = () => {
    document.addEventListener('veggaNavigationOptionSelected', (ev) => {
      const isFakeNavigation = ev.target === fakeNavigation;
      let { state } = (ev as CustomEvent).detail.item.dataset;

      const url = window.location.href;

      const match = url.match(/\/(\d+)\//);
      let deviceId: string;

      if (match) {
        deviceId = match[1];
      }

      state = state.includes('.') ? (state as any).replaceAll('.', '/') : state;

      (fakeNavigation as any).optionSelected = (ev as CustomEvent).detail.index;

      if (!isFakeNavigation) {
        return;
      }

      // if fake navigation, use single spa navigateToUrl() to emulate original
      // navigation to the correct url
      if (state === 'history' || state === 'drainages') {
        navigateToUrl(`/irrigation-controls/devices/${deviceId}/${state}`);
      } else {
        navigateToUrl(`/irrigation-control/unit/${deviceId}/${state}`);
      }
    })
  }
}

function createFakeHeaderContainer(header: HTMLElement, navigation: HTMLElement): HTMLDivElement {
  const fakeHeaderContainer = document.createElement('div');
  fakeHeaderContainer.id = 'fakeHeaderContainer';
  const { x, y } = header.getBoundingClientRect();
  const bodyScrollHeight = document.body.scrollTop;

  fakeHeaderContainer.style.width = '100%';
  fakeHeaderContainer.style.position = 'absolute';
  fakeHeaderContainer.style.top = `${y + bodyScrollHeight}px`;
  fakeHeaderContainer.style.left = `${x + bodyScrollHeight}px`;
  fakeHeaderContainer.style.width = `${header.clientWidth}px`;
  fakeHeaderContainer.style.backgroundColor = '#e7e7e7';
  navigation.style.backgroundColor = '#e7e7e7';

  return fakeHeaderContainer;
}

// TODO - Investigate how to manage custom event in Google Analytics
// function createUserDataLayer() {
//
//     const dataLayerItem = DataLayerStore.getInstance();
//     const organization = OrganizationStore.getInstance();
//     const authStore = AuthStore.getInstance();
//     const userDataLayer: UserDataLayer = {
//       idUser:'',
//       typeUser: '',
//       type: '',
//       plan: '',
//       role: '',
//       sessionId: uuidv4(),
//       country: ''
//     };
//
//     if(organization.get()) {
//       userDataLayer.typeUser = organization.isMigratedOrg() ? 'migrated' : 'new'
//       userDataLayer.plan = organization.getPlan();
//       userDataLayer.role = organization.getRole();
//       userDataLayer.type = organization.getType();
//     }
//     if(authStore.get()) {
//       userDataLayer.idUser = sha256(authStore.getLoggedInUser().username)
//     }
//     dataLayerItem.setUserDataLayer(userDataLayer);
// }
