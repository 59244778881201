import { UNITS } from '../utils/units.enum';
import { Subject, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getContainer } from '@vegga/front-store';
(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('externalmodController', externalmodController);

  externalmodController.$inject = ['$scope', '$state', 'commFactory', 'unitFactory', '$filter'];

  function externalmodController($scope, $state, commFactory, unitFactory, $filter) {
    var vm = this;
    vm.linkBoxes;
    var external_modules_types;
    vm.selectActive = false;
    vm.UNITS = UNITS;
    vm.destroy$ = new Subject();
    vm.communicationsFacade = getContainer().resolve('communicationsFacade');
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$
        .pipe(takeUntil(vm.destroy$))
        .pipe(takeUntil(vm.destroy$))
        .subscribe((currentUnit) => {
          if (!currentUnit) {
            return;
          }
          loadData(currentUnit);
        });

      vm.openModule = openModule;
    }

    function loadData(currentUnit) {
      vm.currentState = $state.includes;
      vm.currentUnit = currentUnit;
      vm.save = save;
      vm.cancel = cancel;

      vm.filter = {
        type: [],
        state: [],
      };

      vm.updateFilters = updateFilters;

      external_modules_types = {
        1: { type: 'Monocable', name: 'AM120' },
        2: { type: 'Radio', name: 'AR868-16' },
        3: { type: 'Radio', name: 'AR24' },
        4: { type: 'Radio', name: 'AR433' },
        5: { type: 'Radio', name: 'MI' },
        6: { type: $filter('translate')('comm.expansion_modules'), name: 'ME' },
        7: { type: 'Radio', name: 'R868' },
        8: { type: 'Radio', name: 'AgroBee' },
        9: { type: 'Radio', name: 'AgroBeeL' },
        10: { type: 'Cable', name: 'SDI12' },
      };

      vm.typeFilterOptions = [
        { type: 1, name: 'AM120' },
        { type: 2, name: 'AR868-16' },
        { type: 3, name: 'AR24' },
        { type: 4, name: 'AR433' },
        { type: 5, name: 'MI' },
        { type: 6, name: 'ME' },
        { type: 7, name: 'R868' },
        { type: 8, name: 'AgroBee' },
        { type: 9, name: 'AgroBeeL' },
        { type: 10, name: 'SDI12' },
      ];

      loadLinkBoxes();
    }

    function updateFilters($event, name) {
      const values = $event.detail.map((item) => item.value);
      vm.filter = {
        ...vm.filter,
        [name]: values,
      };

      filterLinkBoxes();
    }

    function filterLinkBoxes() {
      const filteredLinkBoxesByType = vm.linkBoxes.filter((box) =>
        vm.filter.type.length ? vm.filter.type.includes(box.pk.type) : vm.linkBoxes
      );

      vm.filteredLinkBoxes = filteredLinkBoxesByType.filter((box) =>
        vm.filter.state.length ? vm.filter.state.includes(box.state) : filteredLinkBoxesByType
      );

      vm.showExternalModBus =
        vm.currentUnit.type !== UNITS.A_4500
          ? false
          : (vm.filter.state.length ? vm.filter.state.includes(vm.externalModBus.state) : true) &&
            (vm.filter.type.length ? vm.filter.type.includes('modbus') : true);

      vm.showEmtpyResultsAlert = !vm.filteredLinkBoxes.length && !vm.showExternalModBus;
    }

    function loadExternalModBus() {
      commFactory.modBusExterno(vm.currentUnit.id).then((list) => {
        vm.externalModBus = vm.linkBoxes.find((box) => box.pk.type === 11);
        vm.externalModBus.typeName = 'externModbus'; // used i18n name in order to translate it on the html
        vm.externalModBus.externalModules = list.plain();
        setLinkBoxState(vm.externalModBus);
        filterLinkBoxes();
      });
    }

    function loadLinkBoxes() {
      commFactory.linkBox(vm.currentUnit.id).then((list) => {
        vm.linkBoxes = list.plain();
        checkLinkStates();

        const linkBoxesTypes = vm.linkBoxes.map((box) => box.pk.type);
        vm.typeFilterOptions = vm.typeFilterOptions.filter((box) => linkBoxesTypes.includes(box.type));

        if (vm.currentUnit.type === UNITS.A_4500 && vm.currentUnit.inoptions.optionPlus) {
          loadExternalModBus();
        }
      });
    }

    async function checkLinkStates() {
      let agrobee = vm.currentUnit.inoptions.agrobee;
      let agrobeel = vm.currentUnit.inoptions.agrobeel;
      let agrobeel1;
      let agrobeel2;

      if (vm.currentUnit.type === UNITS.A_4500) {
        if (vm.currentUnit.inoptions.agroBeeL1) {
          agrobeel1 = true;
        }
        if (vm.currentUnit.inoptions.agroBeeL2) {
          agrobeel2 = true;
        }
        if (vm.currentUnit.version >= 121) {
          vm.communicationsFacade.loadModulesA4500Sdi12(vm.currentUnit.id);
          let data = await firstValueFrom(vm.communicationsFacade.a4500Sdi12$.pipe(takeUntil(vm.destroy$)));
          vm.linkBoxes.push({
            pk: {
              type: 10,
              id: data[0].linkBox,
              deviceId: vm.currentUnit.id,
            },
            externalModules: data,
            modeTx: null,
            networkCode: null,
          });
        }
      }

      let sdi12 = true; // Ja no es una opció, sempre será actiu.
      vm.linkBoxes.forEach((box) => {
        if (box.pk.type === 11) return;
        var model = external_modules_types[box.pk.type];
        box.type = model.type;
        box.typeName = model.name;

        switch (box.pk.type) {
          case 1:
            if (vm.currentUnit.inoptions.optionMonocable || vm.currentUnit.inoptions.am) {
              if (vm.currentUnit.type === UNITS.A_4500) {
                box.enabled = box.active;
                setAR433AM120State(box);
              } else {
                box.enabled = true;
                if (box.xCommunicate) {
                  if (box.pk.type === 7) {
                    if (box.port !== null && box.port !== 0) {
                      box.state = 'comm';
                    } else {
                      box.state = 'noconf';
                    }
                  } else {
                    box.state = 'comm';
                  }
                } else if (box.active && !box.xCommunicate) {
                  box.state = 'error';
                } else {
                  box.state = 'nocomm';
                }
              }
            }
            break;
          case 2:
          case 3:
          case 4:
          case 7:
            if (vm.currentUnit.inoptions.optionRadio || vm.currentUnit.inoptions.ar) {
              if (vm.currentUnit.type === UNITS.A_4500) {
                box.enabled = box.active;
                setAR433AM120State(box);
              } else {
                box.enabled = true;
                if (box.xCommunicate) {
                  if (box.pk.type === 7) {
                    if (box.port !== null && box.port !== 0) {
                      box.state = 'comm';
                    } else {
                      box.state = 'noconf';
                    }
                  } else {
                    box.state = 'comm';
                  }
                } else if (box.active && !box.xCommunicate) {
                  box.state = 'error';
                } else {
                  box.state = 'nocomm';
                }
              }
            }
            break;
          case 5:
          case 6:
            if (box.xCommunicate) {
              if (box.pk.type === 7) {
                if (box.port !== null && box.port !== 0) {
                  box.state = 'comm';
                } else {
                  box.state = 'noconf';
                }
              } else {
                box.state = 'comm';
              }
            } else if (box.active && !box.xCommunicate) {
              box.state = 'error';
            } else {
              box.state = 'nocomm';
            }
            box.enabled = true;
            break;
          case 8:
            if (agrobee) {
              setLinkBoxState(box);
              box.enabled = true;
            }
            break;
          case 9:
            if (vm.currentUnit.type !== UNITS.A_4500) {
              if (agrobeel) {
                box.enabled = true;
                setLinkBoxState(box);
              }
              break;
            } else {
              if (agrobeel1 && box.pk.id === 1) {
                box.enabled = true;
                setLinkBoxState(box);
              } else if (agrobeel2 && box.pk.id === 2) {
                box.enabled = true;
                setLinkBoxState(box);
              }
            }
            break;
          case 10:
            if (sdi12) {
              box.enabled = true;
              box.state = 'comm';
            }
            break;
        }
      });
      filterLinkBoxes();
    }

    function setAR433AM120State(linkBox) {
      switch (linkBox.xcomunica) {
        case 0:
          linkBox.state = 'noconf';
          break;
        case 1:
          linkBox.state = 'nocomm';
          break;
        case 2:
          linkBox.state = 'comm';
          break;
        case 3:
          linkBox.state = 'error';
          break;
        default:
          linkBox.state = 'noconf';
      }
    }

    function setLinkBoxState(linkBox) {
      switch (linkBox.xState) {
        case 0:
          linkBox.state = 'noconf';
          break;
        case 1:
          linkBox.state = 'nocomm';
          break;
        case 2:
          linkBox.state = 'comm';
          break;
        case 3:
          linkBox.state = 'error';
          break;
        default:
          linkBox.state = 'noconf';
      }
    }

    function openModule(_e, box) {
      const veggaOverlay = document.querySelector('vegga-overlay');
      veggaOverlay.show();
      vm.box = box;
      $state.go('externalmodules.detail', { unit: vm.currentUnit, box: box, type: box.typeName });
    }

    function save() {
      $scope.$broadcast('saveEM');
    }

    function cancel() {
      $scope.$broadcast('cancelEM');
      $state.go('externalmodules');
    }

    $scope.$on('emActive', (e, args) => {
      vm.selectActive = args;
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
    });
  }
})();
