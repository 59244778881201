import { getContainer } from '@vegga/front-store';
import moment from 'moment/moment';
import { Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { navigateToUrl } from 'single-spa';
import { UNITS } from '../utils/units.enum';
import { getType, unitTimeFormatter } from '../utils/utils';
import { VIEWS } from '../utils/views.enum';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')
    //TODO - check moment
    .constant('moment', moment)
    .controller('unitController', unitController);

  unitController.$inject = [
    '$rootScope',
    '$stateParams',
    '$scope',
    '$state',
    '$translate',
    'resFactory',
    '$confirm',
    '$filter',
    '$window',
    'manualFactory',
    'userFactory',
    'unitFactory',
    'refreshFactory',
    'UserData',
    'veggaModalConfirmService',
  ];

  function unitController(
    $rootScope,
    $stateParams,
    $scope,
    $state,
    $translate,
    resFactory,
    $confirm,
    $filter,
    $window,
    manualFactory,
    userFactory,
    unitFactory,
    refreshFactory,
    UserData,
    veggaModalConfirmService
  ) {
    var vm = this;
    vm.menu;
    vm.loadState;
    vm.form;

    // function definition and props for edit unit
    vm.configUnit = configUnit;
    vm.setOverlayState = setOverlayState;
    vm.manualDate = manualDate;
    vm.cancel = cancel;
    vm.save = save;
    vm.manualActions = manualActions;
    vm.goToUnits = () => $state.go('units');
    vm.formConfigUnit;
    vm.unitDTO;
    vm.unitDate;
    vm.unitTime;
    vm.editOverlayView;
    vm.previousState;
    vm.tabIndex = 0;
    vm.preventSkeleton = false;
    vm.showSkeletonFacade = true;
    vm.unitId;
    vm.unitType;
    vm.destroy$ = new Subject();
    vm.devicesFacade = getContainer().resolve('devicesFacade');
    vm.UNITS = UNITS;
    activate();

    function activate() {
      // get skeleton initial state
      vm.showSkeleton = refreshFactory.getSkeletonStatus();
      vm.checkStatus = unitFactory.checkStatus;
      vm.currentState = $state.current.name;
      // subscribe to skeleton status changes
      refreshFactory
        .getSkeletonStatus$()
        .pipe(takeUntil(vm.destroy$))
        .subscribe((isVisible) => {
          vm.showSkeleton = isVisible;
        });

      vm.unitId = $stateParams.unitId || (($state.params && $state.params.unit) || {}).id;

      if (!vm.unitId) {
        $state.go('units');
        return;
      }

      vm.currentState = checkStateInclude;
      vm.loadState = loadState;

      // Set current device in facade
      vm.devicesFacade.devicesICMResponse.value$
        .pipe(
          switchMap((devices) => {
            const unitType = devices.find((device) => device.id === +vm.unitId).type;
            const params = unitType === UNITS.A_7000 ? {} : { add: ['format'] };
            vm.devicesFacade.loadICMDeviceById(vm.unitId, params);
            return vm.devicesFacade.legacyUnitResponse.value$;
          }),
          // get unit data and initialize view

          takeUntil(vm.destroy$)
        )
        .subscribe((unit) => {
          if (unit && Object.keys(unit).length > 0) {
            vm.devicesFacade.currentDeviceSyncStatus = unit.sync.active;
            processCurrentUnit(unit);
            navigateToDefaultState();
          }
        });

      refreshFactory
        .getRefresh$()
        .pipe(takeUntil(vm.destroy$))
        .subscribe(() => {
          vm.devicesFacade.loadICMDevices(UserData.id, true);
          refreshFactory.completeRefresh();
        });

      vm.devicesFacade.isLoading$
        .pipe(
          filter((isLoading) => !isLoading),
          take(1)
        )
        .subscribe((isLoading) => {
          vm.showSkeletonFacade = isLoading && vm.currentUnit.type === UNITS.A_4500;
          // document.querySelector('#deviceCard').querySelector('vegga-card-content').showSkeleton = isLoading;
        });
    }

    function navigateToDefaultState() {
      const currentState = $state.current.name;
      if (currentState === 'unit') {
        switch (vm.currentUnit.type) {
          case UNITS.A_4000:
            $state.go('programs', { unit: vm.currentUnit });
            break;
          case UNITS.A_4500:
            $state.go('a45programs', { unit: vm.currentUnit });
            break;
          case UNITS.A_2500:
          case UNITS.A_BIT:
            $state.go('a25programs', { unit: vm.currentUnit });
            break;
          case UNITS.A_7000:
            $state.go('a7programs', { unit: vm.currentUnit });
            break;
          case UNITS.A_5500:
            $state.go('a55programs', { unit: vm.currentUnit });
            break;
        }
      }
    }

    function processCurrentUnit(unit) {
      vm.currentUnit = unit;
      vm.menu = prepraeMenuAccordingUnitModel(resFactory.menu(vm.currentUnit.type));
      vm.tabIndex = getTabIndex();

      vm.currentUnit.typeName = getType(vm.currentUnit.type);
      vm.currentUnit.ram.lastReception = unitTimeFormatter($translate, vm.currentUnit.ram.lastReception);
      vm.currentUnit.ram.date = unitTimeFormatter($translate, vm.currentUnit.ram.date);
      vm.currentUnit.date = unitTimeFormatter($translate, vm.currentUnit.date);
      vm.currentUnit.statusLabel = `units.${
        vm.currentUnit.status === 'ok' ? (vm.currentUnit.irrigation ? 'irrig' : 'nirrig') : vm.currentUnit.status
      }`;
      vm.currentUnit.irrigationStatus = unitFactory.getIrrigationStatus(vm.currentUnit);

      const { overlayPath } = $stateParams;
      if (overlayPath) {
        // setOverlayState(overlayPath);
        configUnit(null, overlayPath);
      }
    }

    function getTabIndex() {
      let { name, parent } = $state.current;
      if (name.includes('.')) {
        parent = $state.current.name.split('.')[0];
        name = $state.current.name.split('.')[1];
      }
      // user is navigating through overlay, menu state is two url levels up
      // unit/1/programs/1/detail > getting 'programs' in this case
      if (
        Object.keys(VIEWS)
          .map((k) => k.toLowerCase())
          .includes(name)
      ) {
        name = $state.get(parent).name;
      } else if (parent !== 'unit') {
        name = parent;
      }

      // user is coming from units or navigating throught unit-controller navigator
      return $state.current.name === 'unit' ? 0 : vm.menu.findIndex((entry) => entry.state.includes(name));
    }

    function prepraeMenuAccordingUnitModel(menu) {
      if (vm.currentUnit.type === UNITS.A_2500) {
        return menu.filter((item) => {
          return (
            (vm.currentUnit.inoptions.pivots ? item : item.state !== 'pivots') &&
            (vm.currentUnit.inoptions.plus ? item : item.state !== 'conditioner') &&
            (vm.currentUnit.inoptions.plus ? item : item.state !== 'solar')
          );
        });
      } else if (vm.currentUnit.type === UNITS.A_4500) {
        return menu.filter((item) => {
          return (
            (vm.currentUnit.inoptions.optionHidro ? item : item.state !== 'nebulization') &&
            (vm.currentUnit.inoptions.optionPlus ? item : item.state !== 'sectorsgroup') &&
            (vm.currentUnit.inoptions.optionHidro ? item : item.state !== 'drainages')
          );
        });
      } else {
        return menu;
      }
    }

    function checkStateInclude(stateName) {
      if (!$state.includes(stateName)) {
        if (stateName.includes('.detail') || stateName.includes('.edit') || stateName.includes('.config')) {
          stateName = stateName.split('.')[0];
        }
      }

      return $state.includes(stateName);
    }

    function loadState(event) {
      // if same menu option is  clicked, do nothing
      if (vm.tabIndex === event.detail.index) {
        return;
      }

      const item = vm.menu[event.detail.index];
      if (vm.form && vm.form.$dirty) {
        event.preventDefault();
        veggaModalConfirmService
          .showVeggaModalConfirm({
            header: $filter('translate')('programs.edit.cancelq'),
          })
          .then((isConfirmed) => {
            if (!isConfirmed) {
              return;
            }

            $scope.$broadcast('formFromUnitCancel'); //Emetem cancelació de canvis
            vm.form = null;
            if ($state.current.parent !== item.state) {
              vm.tabIndex = event.detail.index;
              $state.go(item.state, { unit: vm.currentUnit, config: item.config });
              vm.showSkeleton = true;
            }
          });
      } else {
        vm.tabIndex = event.detail.index;
        if (
          ((vm.currentUnit.type === UNITS.A_2500 ||
            vm.currentUnit.type === UNITS.A_4500 ||
            vm.currentUnit.type === UNITS.A_4000 ||
            vm.currentUnit.type === UNITS.A_BIT) &&
            item.state === 'history') ||
          (vm.currentUnit.type === UNITS.A_4500 && item.state === 'drainages' && vm.currentUnit.inoptions.optionHidro)
        ) {
          navigateToUrl(`/irrigation-controls/devices/${vm.currentUnit.id}/${item.state}`);
          return;
        }

        if (item && $state.current.parent !== item.state) {
          $state.go(item.state, { unit: vm.currentUnit, config: item.config, id: vm.currentUnit.id });
          vm.showSkeleton = true;
        }
      }
    }

    function backup() {
      vm.unitDTOCopy = {};
      angular.copy(vm.unitDTO, vm.unitDTOCopy);
    }

    function configUnit(event, state) {
      const overlay = document.querySelector('#edit-unit');
      overlay.show();
      vm.editOverlayView = state;
      vm.unitDTO = {
        name: vm.currentUnit.name,
        date: moment(vm.currentUnit.ram.date, 'DD-MM-YYYY HH:mm').toDate(),
        time: moment(vm.currentUnit.ram.date, 'DD-MM-YYYY HH:mm').format('HH:mm'),
      };
      backup();
      if (state === 'manual') {
        $state.go('edit-unit', { unit: vm.currentUnit });
      }
    }

    function setOverlayState(state) {
      if (vm.formConfigUnit && vm.formConfigUnit.$dirty) {
        veggaModalConfirmService
          .showVeggaModalConfirm({
            header: $filter('translate')('programs.prog2'),
            content: $filter('translate')('programs.edit.cancelq'),
          })
          .then((isConfirmed) => {
            if (!isConfirmed) {
              return;
            }
            vm.formConfigUnit.$setPristine();
            angular.copy(vm.unitDTOCopy, vm.unitDTO);
            vm.editOverlayView = state;
            if (state === 'manual') {
              $state.go('edit-unit', { unit: vm.currentUnit });
            }
          });
      } else {
        vm.editOverlayView = state;
        if (state === 'manual') {
          $state.go('edit-unit', { unit: vm.currentUnit });
        }
      }
    }

    function setUnitParams(type, deviceId, action, param1, param2, param3, param4, param5, param6, param7) {
      return {
        type: type,
        deviceId: deviceId,
        action: action,
        parameter1: param1,
        parameter2: param2,
        parameter3: param3,
        parameter4: param4,
        parameter5: param5,
        parameter6: param6,
        parameter7: param7,
      };
    }

    function manualDate() {
      $confirm({ text: 'Enviar orden manual?', title: 'Manual AGRONIC', ok: 'Si', cancel: 'No' }).then(function () {
        vm.devicesFacade
          .updateDeviceName({
            deviceId: vm.currentUnit.id,
            deviceType: vm.currentUnit.type,
            deviceNameRequest: { deviceName: vm.unitDTO.name },
          })
          .subscribe();
        var modalInstance = manualFactory.showLoadingBar();
        let params;
        switch (vm.currentUnit.type) {
          case 2:
            params = setUnitParams(
              vm.currentUnit.type,
              vm.currentUnit.id,
              20,
              moment(vm.unitDTO.time, 'HH:mm').hours(),
              moment(vm.unitDTO.time, 'HH:mm').minutes(),
              0,
              moment(vm.unitDTO.date).date(),
              moment(vm.unitDTO.date).month() + 1,
              Number(moment(vm.unitDTO.date).format('YY')),
              moment(vm.unitDTO.date).isoWeekday()
            );
            break;
          case 3:
            params = setUnitParams(
              vm.currentUnit.type,
              vm.currentUnit.id,
              14,
              0,
              0,
              moment(vm.unitDTO.time, 'HH:mm').minutes(),
              moment(vm.unitDTO.time, 'HH:mm').hours(),
              moment(vm.unitDTO.date).date(),
              moment(vm.unitDTO.date).month() + 1,
              Number(moment(vm.unitDTO.date).format('YY'))
            );
            break;
          case 4:
            params = setUnitParams(
              vm.currentUnit.type,
              vm.currentUnit.id,
              13,
              0,
              0,
              moment(vm.unitDTO.time, 'HH:mm').minutes(),
              moment(vm.unitDTO.time, 'HH:mm').hours(),
              moment(vm.unitDTO.date).date(),
              moment(vm.unitDTO.date).month() + 1,
              Number(moment(vm.unitDTO.date).format('YY'))
            );
            break;
          case 5:
            params = setUnitParams(
              vm.currentUnit.type,
              vm.currentUnit.id,
              23,
              0,
              0,
              moment(vm.unitDTO.time, 'HH:mm').minutes(),
              moment(vm.unitDTO.time, 'HH:mm').hours(),
              moment(vm.unitDTO.date).date(),
              moment(vm.unitDTO.date).month() + 1,
              Number(moment(vm.unitDTO.date).format('YY'))
            );
            break;
          case 6:
            params = setUnitParams(
              vm.currentUnit.type,
              vm.currentUnit.id,
              14,
              0,
              0,
              moment(vm.unitDTO.time, 'HH:mm').minutes(),
              moment(vm.unitDTO.time, 'HH:mm').hours(),
              moment(vm.unitDTO.date).date(),
              moment(vm.unitDTO.date).month() + 1,
              Number(moment(vm.unitDTO.date).format('YY'))
            );
            break;
          case 7:
            params = setUnitParams(
              vm.currentUnit.type,
              vm.currentUnit.id,
              24,
              moment(vm.unitDTO.time, 'HH:mm').seconds(),
              moment(vm.unitDTO.time, 'HH:mm').minutes(),
              moment(vm.unitDTO.time, 'HH:mm').hours(),
              moment(vm.unitDTO.date).date(),
              moment(vm.unitDTO.date).month() + 1,
              Number(moment(vm.unitDTO.date).format('YY')),
              0
            );
            break;
        }
        manualFactory.genericManualAction(params.deviceId, params).then(() => {});
        modalInstance.result.then(() => {
          vm.formConfigUnit.$setPristine();
        });
      });
    }

    function cancel($event) {
      if (vm.formConfigUnit && vm.formConfigUnit.$dirty) {
        typeof $event !== 'undefined' ? $event.preventDefault() : null;
        $confirm({
          text: $filter('translate')('programs.edit.cancelq'),
          title: $filter('translate')('programs.prog2'),
        }).then(() => {
          vm.formConfigUnit.$setPristine();
          angular.copy(vm.unitDTOCopy, vm.unitDTO);
          if (typeof $event !== 'undefined') {
            document.querySelector('vegga-overlay').dismiss();
          } else {
            setOverlayState('info');
          }
        });
      } else {
        if (typeof $event !== 'undefined') {
          document.querySelector('vegga-overlay').dismiss();
        } else {
          setOverlayState('info');
        }
      }
    }

    function save() {
      vm.manualDate();
    }

    function manualActions($event) {
      vm.configUnit($event, 'manual');
    }

    $scope.$on('formToUnitUpdated', (_, args) => {
      vm.form = args;
    });

    $rootScope.$on('showSkeleton', (_, data) => {
      vm.showSkeleton = data && !vm.preventSkeleton;
    });

    $rootScope.$on('preventSkeleton', (_, data) => {
      vm.preventSkeleton = data;
    });

    // $rootScope.$on('reloadData', (ev, data) => {
    //   reloadUnits();
    // });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
      vm.previousState = null;
      vm.devicesFacade.legacyUnitResponse.clear();
    });

    $window.onpopstate = function ($event) {
      const currentState = $state.current.name;

      vm.previousState = vm.currentState;
      vm.currentState = currentState;
      // $event.singleSpaTrigger (Mozilla) means that browser back button is pressed
      if (!!$event.singleSpaTrigger || vm.previousState === 'unit') return;

      // If true, means that we are not in a section, so we redirect to units
      if (currentState === 'unit') {
        $state.go('units');
        return;
      }

      if (vm.menu) {
        vm.tabIndex = getTabIndex();
      }
    };
  }
})();
