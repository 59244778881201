import { BehaviorSubject, distinctUntilChanged, fromEvent, map, merge, Observable, skip, Subject } from "rxjs";

export enum SidebarStatesEnum {

}

export enum BreakpointsEnum {
    XS = 0,
    SM = 576,
    MD = 768,
    LG = 992,
    XL = 1200,
}

/**
 * In charge of sidebar style effects from navbar and sidebar events
 * also manages communication between both micros
 */
export class SidebarHandler {
    /**
 * Subject which holds sidebar state value, which it is a boolean that represents folded state, being folded when true.
 */
    private sidebarStateSubject = new BehaviorSubject<boolean>(window.innerWidth > BreakpointsEnum.LG && window.innerWidth < BreakpointsEnum.XL);

    sidebarState$: Observable<boolean>;

    constructor() {
        this.sidebarState$ = this.sidebarStateSubject.asObservable().pipe();
        this.listenSidebarEvents();
    }

    /**
    * Navbar and sidebar emits events when user hovers or blurs it, next sidebar state is calculated and pushed to 
    * subject, which is passed as single-spa props to other micros as observable
    */
    listenSidebarEvents = () => {
        merge(
            fromEvent(window, 'sidebar:mouseleave').pipe(map(() => 'sidebar:mouseleave')), 
            fromEvent(window, 'sidebar:mouseenter').pipe(map(() => 'sidebar:mouseenter'))).subscribe((ev) => {
            const nextState = ev === 'sidebar:mouseleave';
            if (window.innerWidth > BreakpointsEnum.LG && window.innerWidth < BreakpointsEnum.XL) {
                this.sidebarStateSubject.next(nextState);
            }
        })

        fromEvent(window, 'resize')
            .pipe(
                map(() => window.innerWidth),
                map(this.getBreakpoint),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (window.innerWidth < BreakpointsEnum.LG) {
                    this.sidebarStateSubject.next(false);
                    return;
                }

                this.sidebarStateSubject.next(window.innerWidth < BreakpointsEnum.XL);
            })

        fromEvent(window, 'sidebar:clicked-menu-item').subscribe(() => {
            if (window.innerWidth > BreakpointsEnum.LG && window.innerWidth < BreakpointsEnum.XL) {
                this.sidebarStateSubject.next(true)
            }
        })

        fromEvent(window, 'sidebar:hide').subscribe(() => {
            (document.querySelector('.vegga__container').firstChild as HTMLElement).style.display = 'none';
        });

        fromEvent(window, 'sidebar:show').subscribe(() => {
            (document.querySelector('.vegga__container').firstChild as HTMLElement).style.display = 'block';
        })
    }

    getBreakpoint(width: number): keyof typeof BreakpointsEnum {
        if (width < BreakpointsEnum.SM) return 'XS';
        if (width < BreakpointsEnum.MD) return 'SM';
        if (width < BreakpointsEnum.LG) return 'MD';
        if (width < BreakpointsEnum.XL) return 'LG';
        return 'SM';
    }

}

const sidebarHandlerI = new SidebarHandler();

export default sidebarHandlerI;