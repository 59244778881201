import moment from 'moment/moment';
import { Subject, forkJoin, of } from 'rxjs';
import { map, switchMap, takeUntil, take, tap } from 'rxjs/operators';
import { getDeviceConfig } from '../utils/device-config';
import { FERT_MODE } from '../utils/enums/programs/programs-fertilization-mode.enum';
import { FERT_TYPE } from '../utils/enums/programs/programs-fertilization-type.enum';
import { UNITS, UNITS_V2 } from '../utils/units.enum';
import { showLoader, hideLoader } from '@vegga/front-utils';
import { getContainer } from '@vegga/front-store';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('fertilizersController', fertilizersController);

  fertilizersController.$inject = [
    '$scope',
    '$state',
    'manualFactory',
    '$confirm',
    'progFactory',
    'fertilizerFactory',
    'sensorsFactory',
    'unitFactory',
    '$filter',
    '$q',
    'utilsFactory',
    'configFactory',
    'conditionerFactory',
    '$rootScope',
  ];

  function fertilizersController(
    $scope,
    $state,
    manualFactory,
    $confirm,
    progFactory,
    fertilizerFactory,
    sensorsFactory,
    unitFactory,
    $filter,
    $q,
    utilsFactory,
    configFactory,
    conditionerFactory,
    $rootScope
  ) {
    var vm = this;
    vm.programsFacade = getContainer().resolve('programsFacade');
    vm.activeList;
    vm.overlayEl;
    vm.formFertilizer;
    vm.tabState = 'general';
    vm.destroy$ = new Subject();
    vm.fertilizersFacade = getContainer().resolve('fertilizersFacade');
    vm.devicesFacade = getContainer().resolve('devicesFacade');

    vm.showAlert = false;

    loadInitialData();

    activate();

    function activate() {
      vm.devicesFacade.legacyUnitResponse.value$
        .pipe(
          switchMap((unit) => {
            vm.currentUnit = unit;
            if (vm.currentUnit.type === UNITS.A_4500) {
              vm.headerId = 1;
            }

            if (vm.currentUnit.type === UNITS.A_2500) {
              initSubscriptions();
            }
            vm.deviceConfig = getDeviceConfig(unit).fertilization;
            vm.fertilizersFacade.loadFertilizer(vm.currentUnit.id, UNITS_V2[vm.currentUnit.type]);
            return getFertilization();
          }),
          takeUntil(vm.destroy$)
        )
        .subscribe(([fertConfig, programs, phref]) => {
          if (vm.currentUnit.type !== UNITS.A_4500) {
            vm.phref = phref;
            vm.fertConfig = fertConfig;
            if (vm.currentUnit.type !== UNITS.A_2500 && vm.currentUnit.type !== UNITS.A_BIT) vm.programs = programs;

            if (vm.currentUnit.type === UNITS.A_4000) {
              vm.fertilizersUniformRel = fertConfig.fertilizers.map((fert) =>
                fert.uniformRel / 100 > 100 ? 100 : fert.uniformRel / 100
              );
            }
          }
        });

      vm.currentState = $state.includes;
      vm.fertilizersNumber = [...Array(8)].map((u, i) => i);
    }

    function initSubscriptions() {
      vm.fertilizersFacade.fertilizerResponse.value$.pipe(takeUntil(vm.destroy$)).subscribe((fertilizer) => {
        if (!Object.keys(fertilizer).length) {
          return;
        }

        const { type, configurationStatus } = vm.deviceConfig;

        vm.fertilizer = fertilizer;

        vm.fertilizersConfig = fertilizer.fertilizersConfig.map((config) => {
          const fertConfigStatus = configurationStatus.find((status) => status.key === config.configurationStatus);
          return {
            ...config,
            configurationStatus: fertConfigStatus,
          };
        });

        vm.fertilizationType = type[fertilizer.fertilizationType].type;
        vm.fertilizer.application = type[fertilizer.fertilizationType].application;

        vm.programStatus = fertilizer.programStatus
          .filter(
            (progStatus) =>
              progStatus.irrigationDetail.some((detail) => detail.status) && progStatus.irrigationRemaining > 0
          )
          .map((progStatus) => {
            return {
              ...progStatus,
              irrigStatus: progStatus.irrigationDetail
                .filter((irrigDetail) => irrigDetail.status)
                .reduce((_, detail) => getStatusConfig(detail.status, progStatus), { label: '', style: '' }),
              parsedIrrigationAmount: parseTimeFromSeconds(
                progStatus.irrigationAmount,
                'AUTOGENERATED.MEASUREMENT_UNITS.MEU000_HOURS_MINUTES'
              ),
              parsedIrrigationRemaining: `${
                progStatus.irrigationRemaining
                  ? parseTimeFromSeconds(
                      progStatus.irrigationRemaining,
                      'AUTOGENERATED.MEASUREMENT_UNITS.MEU000_HOURS_MINUTES'
                    )
                  : `00:00 ${$filter('translate')('AUTOGENERATED.MEASUREMENT_UNITS.MEU000_HOURS_MINUTES')}`
              }`,
              irrigationStartTime: `${moment(progStatus.irrigationDetail[0].start).format('HH:mm')} ${$filter(
                'translate'
              )('AUTOGENERATED.MEASUREMENT_UNITS.MEU000_HOURS_MINUTES')}`,
              irrigationDetail: progStatus.irrigationDetail
                .filter((irrigDetail) => irrigDetail.status)
                .reduce((irrigDetails, irrigDetail) => {
                  const duplicatedFert = irrigDetails.findIndex(
                    (detail) => detail.name === irrigDetail.name && moment(detail.start).isBefore(irrigDetail.start)
                  );
                  if (duplicatedFert !== -1) {
                    irrigDetails[duplicatedFert] = irrigDetail;
                  } else {
                    irrigDetails.push(irrigDetail);
                  }
                  return irrigDetails;
                }, [])
                .map((irrigDetail) => {
                  const fertUnit = irrigDetail.fertilizerUnits;
                  const unitConfig = vm.deviceConfig.measurementUnits.find((unit) => unit.key === fertUnit);
                  const unitTranslateKey = unitConfig && unitConfig.unit.key;
                  const statusConfig = vm.deviceConfig.configurationStatus.find(
                    (status) => status.key === irrigDetail.status
                  );
                  if (statusConfig) {
                    statusConfig.translatedKey = $filter('translate')(`general.${statusConfig.key.toLowerCase()}`);
                  }
                  const agitatorStatus = vm.deviceConfig.configurationStatus.find(
                    (status) => status.key === irrigDetail.agitatorStatus
                  );
                  const isTimeUnit = fertUnit === 'MMSS' || fertUnit === 'HHMM';

                  return {
                    ...irrigDetail,
                    isTimeUnit,
                    ...{
                      statusConfig,
                      agitatorStatus,
                      parsedAmount: isTimeUnit
                        ? parseTimeFromSeconds(irrigDetail.amount, unitTranslateKey, fertUnit)
                        : `${irrigDetail.amount} ${$filter('translate')(unitTranslateKey)}`,
                      parsedAmountRemaining: isTimeUnit
                        ? parseTimeFromSeconds(irrigDetail.amountRemaining, unitTranslateKey, fertUnit)
                        : `${irrigDetail.amountRemaining || 0} ${$filter('translate')(unitTranslateKey)}`,
                      agitatorRemaining: isTimeUnit
                        ? parseTimeFromSeconds(irrigDetail.agitatorRemaining, unitTranslateKey, fertUnit)
                        : `${irrigDetail.agitatorRemaining || 0} ${$filter('translate')(unitTranslateKey)}`,
                    },
                  };
                }),
            };
          });

        // WORKAROUND:
        // for some reason, in angularjs bars are missing 1px of bar width
        // so we have to add it manually
        setTimeout(() => {
          const bars = document.querySelectorAll('vegga-progress-bar');
          bars.forEach((bar) => {
            const barEl = bar.shadowRoot.querySelector('.vegga-progress-bar__container--bar');
            if (!barEl) {
              return;
            }
            const currentWidth = barEl.style.width.replace('px', '');
            if (!+currentWidth) {
              return;
            }
            barEl.style.width = `${+currentWidth + 1}px`;
          });
        }, 2000);
      });
    }

    function getStatusConfig(status, progStatus) {
      const isIrrigating =
        (status === 'FINALIZED' || status === 'PENDING') &&
        progStatus.irrigationRemaining < progStatus.irrigationAmount;
      const label = $filter('translate')(isIrrigating ? `programs.irrigating` : `general.${status.toLowerCase()}`);
      const style = getStatusStyle(status, isIrrigating);
      return { style, label };
    }

    function getStatusStyle(status, isIrrigating) {
      switch (status) {
        case 'FINALIZED':
          return isIrrigating ? 'info' : 'default';
        case 'EXECUTING':
          return 'success';
        case 'PENDING':
          return 'default';
        default:
          return 'default';
      }
    }

    function parseTimeFromSeconds(time, measurementKey, format = 'HHMM') {
      const duration = moment.duration(time, 'seconds');
      return format === 'HHMM'
        ? `${parseTime(duration.hours())}:${parseTime(duration.minutes())} ${$filter('translate')(measurementKey)}`
        : `${parseTime(duration.minutes())}:${parseTime(duration.seconds())} ${$filter('translate')(measurementKey)}`;
    }

    /**
     * Parses number to time, ej: 1 will become 01;
     * @param time time to be parsed
     * @param format format to tell moment what unit time it is
     */
    function parseTime(time) {
      if (time === 0) {
        return '00';
      }

      return `${time < 10 ? '0' : ''}${time.toString()}`;
    }

    function loadInitialData() {
      vm.formatFertilizerUnit = formatFertilizerUnit;
      vm.formatSignedIrr = formatSignedIrr;
      vm.formatSignedFert = formatSignedFert;
      vm.getCalculatedFert = getCalculatedFert;
      vm.getTotalFert = getTotalFert;
      vm.getParsed = getParsed;
      vm.checkValue = checkValue;
      vm.formatFert55 = formatFert55;
      vm.getFertF5Text55 = getFertF5Text55;
      vm.endAlarmPH = endAlarmPH;
      vm.endAlarmCE = endAlarmCE;
      vm.navigate = navigate;
      vm.UNITS = UNITS;

      vm.getProgrammedPropFertValueMax = getProgrammedPropFertValueMax;
      vm.getProgrammedPropIrrigValueMax = getProgrammedPropIrrigValueMax;
      vm.getFormatedFert = getFormatedFert;
      vm.getFertValueMax = getFertValueMax;
      vm.getFormatedIrrig = getFormatedIrrig;
      vm.getFormatedQuantity = getFormatedQuantity;
      vm.getFormatedFito = getFormatedFito;
      vm.existsFertValue = existsFertValue;
      vm.existsCEFertValue = existsCEFertValue;
      vm.existsPropFertValue = existsPropFertValue;
      vm.showHeaders = showHeaders;
      vm.getFertilizerTypeLabel = getFertilizerTypeLabel;
      vm.getProgrammedFitoValue1 = getProgrammedFitoValue1;
      vm.getProgrammedFitoValue2 = getProgrammedFitoValue2;
      vm.getProgrammedReferencePH = getProgrammedReferencePH;
      vm.barClickHandler = barClickHandler;

      vm.FERT_MODE = FERT_MODE;
      vm.FERT_TYPE = FERT_TYPE;
    }

    function getFertilization() {
      switch (vm.currentUnit.type) {
        case UNITS.A_4000:
          return getFertilization4000();
        case UNITS.A_2500:
        case UNITS.A_BIT:
          return getFertilization2500();
        case UNITS.A_7000:
          vm.fertConfig = vm.currentUnit.fertilizer;
          return getFertilizationA700();
        case UNITS.A_5500:
          vm.fertConfig = vm.currentUnit.fertilizer;
          return getFertilizationA5500();
        case UNITS.A_4500:
          vm.fertConfig = { id: vm.currentUnit.id };
          return loadData();
      }
    }

    function navigate(state) {
      const { id } = vm.fertConfig || vm.parameters;
      $state.go(`fertilizer.${state}`, { fertId: id });
    }

    function showHeaders(headerId) {
      vm.headerId = headerId;
      loadData().subscribe();
    }

    function loadData() {
      let queries = [];
      showLoader();
      queries.push(
        fertilizerFactory.getFertilizationHeaders(vm.currentUnit.id),
        fertilizerFactory.getGeneralFertilizationHeader(vm.currentUnit.id, vm.headerId),
        progFactory.getFitos(vm.currentUnit.id),
        configFactory.getHeaderTanks(vm.currentUnit.id, vm.headerId),
        conditionerFactory.getFertConditioners(vm.currentUnit.id, vm.headerId)
      );

      return forkJoin(queries).pipe(
        tap((response) => {
          hideLoader();
          loadFertilizersHeaders(response[0]);
          loadGeneralFertilizersHeader(response[1]);
          loadFitos(response[2]);
          loadGeneralTanksHeader(response[3]);
          loadFertConditioners(response[4]);
          if (vm.fertHeader.fertilizersMode === 0 && vm.fertHeader.ceSecurity > 0) {
            loadCESecurityAnalogSensor(vm.fertHeader.ceSecurity);
          }
          if (vm.fertHeader.fertilizersMode === 0 && vm.fertHeader.ceInput > 0) {
            loadCEEntradaAnalogSensor(vm.fertHeader.ceInput);
          }
          if (vm.fertHeader.fertilizersMode === 0 && vm.fertHeader.pHSecurity > 0) {
            loadPHSecurityAnalogSensor(vm.fertHeader.pHSecurity);
          }
          if (vm.fertHeader.fertilizersMode === 0 && vm.fertHeader.pHRegulation > 0) {
            loadPHRegulationAnalogSensor(vm.fertHeader.pHRegulation);
          }
        }),
        takeUntil(vm.destroy$)
      );
    }

    function barClickHandler(detail) {
      vm.clickedIrrigationDetail = detail;
      const detailOverlay = document.querySelector('#irrigationDetailOverlay');
      detailOverlay.show();
    }

    function loadPHSecurityAnalogSensor(sensorId) {
      sensorsFactory.analogById(vm.currentUnit.id, sensorId).then((response) => {
        let result = response.plain();
        vm.fertHeader.pHSecuritySensor = result;
      });
    }

    function loadPHRegulationAnalogSensor(sensorId) {
      sensorsFactory.analogById(vm.currentUnit.id, sensorId).then((response) => {
        let result = response.plain();
        vm.fertHeader.pHRegulationSensor = result;
      });
    }

    function loadCESecurityAnalogSensor(sensorId) {
      sensorsFactory.analogById(vm.currentUnit.id, sensorId).then((response) => {
        let result = response.plain();
        vm.fertHeader.ceSecuritySensor = result;
      });
    }

    function loadCEEntradaAnalogSensor(sensorId) {
      sensorsFactory.analogById(vm.currentUnit.id, sensorId).then((response) => {
        let result = response.plain();
        vm.fertHeader.ceInputSensor = result;
      });
    }

    function loadFertConditioners(response) {
      vm.conditioners = response.plain();
    }

    function loadGeneralTanksHeader(response) {
      vm.tanks = response.plain();
    }

    function loadFitos(response) {
      let allFitos = response.plain();
      vm.headerFito1 = allFitos[vm.headerId * 2 - 2];
      vm.headerFito2 = allFitos[vm.headerId * 2 - 1];
      if (vm.fertHeader.xNumProgramTF > 0) {
        progFactory
          .program(vm.currentUnit.id, vm.fertHeader.xNumProgramTF, { 'init-program-ferts': true })
          .then((response) => {
            vm.programFertTF = response.plain();
          });
      }
    }

    function loadGeneralFertilizersHeader(response) {
      vm.genFertHeader = response.plain();
      for (let genFertHeader of vm.genFertHeader) {
        const fertValue = genFertHeader.xQuantity;
        const irrigValue = fertValue % Math.pow(2, 16);
        const newFertValue = (fertValue - irrigValue) / Math.pow(2, 16);
        genFertHeader.xPropFert = newFertValue;
        genFertHeader.xPropIrrig = irrigValue;
      }
      loadProgramsAndFerts();
    }

    function loadProgramsAndFerts() {
      if (vm.fertHeader.xNumProgramCE > 0) {
        progFactory
          .program(vm.currentUnit.id, vm.fertHeader.xNumProgramCE, { 'init-program-ferts': true, header: vm.headerId })
          .then((response) => {
            vm.programFerts = response.plain();

            vm.fertType = vm.programFerts.fertType;
          });
      } else {
        for (let fert of vm.genFertHeader) {
          if (fert.xNProgram > 0) {
            progFactory.program(vm.currentUnit.id, fert.xNProgram, { 'init-program-ferts': true }).then((response) => {
              fert.program = response.plain();

              // it can be assigned here since fertilizers from same header must be same type
              vm.fertType = fert.program.fertType;
            });
          }
        }
      }
    }

    function getProgrammedPropFertValueMax(genFertHeader, idx) {
      let value;
      if (genFertHeader.program.programType === 0) {
        if (!genFertHeader.program.xSubProgramInProgres) return 0;
        // subprogram
        eval(
          `value = genFertHeader.program.progFerts[genFertHeader.program.xSubProgramInProgress-1].proportionalParsedFertValue${idx}_1`
        );
      } else {
        // lineal
        eval(`value = genFertHeader.program.progFerts[0].proportionalParsedFertValue${idx}_1`);
      }
      return value;
    }

    function getProgrammedPropIrrigValueMax(genFertHeader, idx) {
      let value;
      if (genFertHeader.program.programType === 0) {
        if (!genFertHeader.program.xSubProgramInProgres) return 0;
        // subprogram
        eval(
          `value = genFertHeader.program.progFerts[genFertHeader.program.xSubProgramInProgress-1].proportionalParsedFertValue${idx}_2`
        );
      } else {
        eval(`value = genFertHeader.program.progFerts[0].proportionalParsedFertValue${idx}_2`);
      }
      return value;
    }

    function getProgrammedFitoValue1() {
      let value;
      if (vm.programFertTF.programType === 0) {
        if (!vm.programFertTF.xSubProgramInProgress) return 0;
        // subprogram
        value = vm.programFertTF.progFerts[vm.programFertTF.xSubProgramInProgress - 1].fitosValue1;
      } else {
        // lineal
        value = vm.programFertTF.progFerts[0].fitosValue1;
      }
      return value;
    }

    function getProgrammedFitoValue2() {
      let value;
      if (vm.programFertTF.programType === 0) {
        if (!vm.programFertTF.xSubProgramInProgress) return 0;
        // subprogram
        value = vm.programFertTF.progFerts[vm.programFertTF.xSubProgramInProgress - 1].fitosValue2;
      } else {
        // lineal
        value = vm.programFertTF.progFerts[0].fitosValue2;
      }
      return value;
    }

    function getProgrammedReferencePH() {
      let value;
      if (vm.programFertPH?.programType === 0) {
        if (!vm.programFertPH?.xSubProgramInProgress) return 0;
        // subprogram
        value = vm.programFertPH?.progFerts[vm.programFertPH.xSubProgramInProgress - 1].referencePH;
      } else {
        // lineal
        value = vm.programFertPH?.progFerts[0].referencePH;
      }
      return value;
    }

    function getFormatedQuantity(genFertHeader) {
      const fertUnits = genFertHeader.program.fertUnits;
      var value = genFertHeader.xQuantity;
      if (
        genFertHeader.program.fertType === vm.FERT_TYPE.PROPORTIONAL_L_M3 ||
        genFertHeader.program.fertType === vm.FERT_TYPE.PROPORTIONAL_cl_L
      ) {
        return value;
      }
      return utilsFactory.getFormatedFertilizationA4500(value, fertUnits);
    }

    function getFormatedFert(genFertHeader, idx) {
      const fertUnits = genFertHeader.program.fertUnits;
      const value = getFertValueMax(genFertHeader, idx);

      return utilsFactory.getFormatedFertilizationA4500(value, fertUnits);
    }

    function getFertValueMax(genFertHeader, idx) {
      let value;
      let subProgramIdx = 0;
      if (genFertHeader.program.programType === 0) {
        // subprogram
        subProgramIdx = genFertHeader.program.xSubProgramInProgress - 1;
      }
      if (!genFertHeader.program.progFerts[subProgramIdx]) return 0;

      eval(`value = genFertHeader.program.progFerts[subProgramIdx].fertValue${idx}`);
      if (
        genFertHeader.program.fertType === vm.FERT_TYPE.PROPORTIONAL_L_M3 ||
        genFertHeader.program.fertType === vm.FERT_TYPE.PROPORTIONAL_cl_L
      ) {
        eval(`value = genFertHeader.program.progFerts[subProgramIdx].proportionalParsedFertValue${idx}_1`);
      }
      return value;
    }

    function getFormatedIrrig(genFertHeader, idx) {
      var value;
      let subProgramIdx = 0;
      if (genFertHeader.program.programType === 0) {
        // subprogram
        subProgramIdx = genFertHeader.program.xSubProgramInProgress - 1;
      }
      if (!genFertHeader.program.progFerts[subProgramIdx]) return 0;
      eval(`value = genFertHeader.program.progFerts[subProgramIdx].proportionalParsedFertValue${idx}_2`);
      return value;
    }

    function getFormatedFito(fitoValue, fitoUnits) {
      return utilsFactory.getFormatedFitoA4500(fitoValue, fitoUnits);
    }

    function existsFertValue(genFertHeader) {
      if (genFertHeader.program) {
        return true;
      } else {
        return false;
      }
    }

    function existsCEFertValue(program, idx) {
      if (program && program.programType === 0) {
        if (!vm.programFerts.xSubProgramInProgress) return 0;
        let exists;
        eval(`exists = program.progFerts[vm.programFerts.xSubProgramInProgress - 1].fertValue${idx} > 0`);
        return exists;
      } else {
        let exists;
        eval(`exists = program.progFerts[0].fertValue${idx} > 0`);
        return exists;
      }
    }

    function getFertilizerTypeLabel(type) {
      switch (type) {
        case 0:
          return $filter('translate')('irridesk.units');
        case 1:
          return $filter('translate')('fert.uni');
        case 2:
          return $filter('translate')('fert.prop') + ' L/m3';
        case 3:
          return $filter('translate')('fert.prop') + ' cl/L';
        case 4:
          return $filter('translate')('general.regce');
        case 5:
          return $filter('translate')('general.regce');
      }
    }

    function existsPropFertValue(genFertHeader) {
      if (genFertHeader.program) {
        return true;
      } else {
        return false;
      }
    }

    function loadFertilizersHeaders(response) {
      vm.fertsHeaders = response.plain();

      vm.programsFacade.clearProgramsResponse();
      vm.programsFacade.getA4500Programs(vm.currentUnit.id);
      vm.programsFacade.programs$.pipe(take(1)).subscribe((programs) => {
        vm.activeProgramsPerHeader = vm.fertsHeaders.map((header) =>
          programs.find((prog) => prog.xState === 1 && prog.xHeader === +header.pk.id)
        );
      });

      vm.fertHeader = vm.fertsHeaders[vm.headerId - 1];

      if (vm.fertHeader.xNumProgramPH > 0) {
        progFactory
          .program(vm.currentUnit.id, vm.fertHeader.xNumProgramPH, { 'init-program-ferts': true })
          .then((response) => {
            vm.programFertPH = response.plain();
          });
      }
    }

    function checkValue(max, volume) {
      if (volume === 0) {
        return 0;
      } else {
        return max - volume;
      }
    }

    function parsePrograms() {
      return map((programs) => {
        return programs.filter((prog) => {
          if (
            prog.irrigation ||
            (vm.currentUnit.type === UNITS.A_4000 && prog.subprograms[prog.xSubprogramCourse]?.value && prog.xValue)
          ) {
            extractFertilizers(prog);
            if (+vm.currentUnit.progtype === UNITS.A_4000) {
              prepareProgressBarA4(prog);
            } else {
              if (vm.currentUnit.type !== UNITS.A_BIT) {
                prepareProgressBar(prog);
              }
            }
          }
          return prog.irrigation;
        });
      });
    }

    function getFertilization2500() {
      vm.programsFacade.clearProgramsResponse();
      if (vm.currentUnit.type === UNITS.A_2500) {
        vm.programsFacade.getA2500Programs(vm.currentUnit.id);
      } else {
        vm.programsFacade.getABITPrograms(vm.currentUnit.id);
      }
      vm.programsFacade.programs$.subscribe((programs) => {
        const parsedPrograms = progFactory.filterA2500ActivePrograms(programs, vm.currentUnit.type);
        vm.programs = parsedPrograms.map((prog) => {
          if (prog.irrigation) {
            extractFertilizers(prog);
            if (vm.currentUnit.type === UNITS.A_BIT) {
              if (prog.fertValue) {
                prepareProgressBarBIT(prog);
              }
            } else {
              prepareProgressBar(prog);
            }
          }
          return prog;
        });

        if (vm.currentUnit.type === UNITS.A_BIT) {
          vm.showAlert = !vm.programs.filter((prog) => !!prog.fertValue && prog.irrigation).length;
        }
      });
      return forkJoin([
        fertilizerFactory.getConfigFertilizers$(vm.currentUnit.id, { add: ['fertilizer', 'agitators'] }).pipe(
          tap((fertConfig) => {
            vm.fertUnit = fertConfig.fertilizerUnits;
            vm.fertDecimals = fertConfig.decimalsFert;
            vm.fertConfig = fertConfig.fertilizers;
          })
        ),

        vm.currentUnit.type === UNITS.A_BIT
          ? progFactory.getPrograms$(vm.currentUnit.id, vm.currentUnit.type).pipe(parsePrograms())
          : of(null),
      ]);
    }

    function getFertilization4000() {
      const hasPh = vm.currentUnit.inoptions && vm.currentUnit.inoptions.ph;
      return forkJoin([
        fertilizerFactory.getConfigFertilizers$(vm.currentUnit.id, { add: ['fertilizer'] }),
        progFactory.getPrograms$(vm.currentUnit.id, vm.currentUnit.type).pipe(parsePrograms()),
        hasPh
          ? fertilizerFactory.getPhref$(vm.currentUnit.id).pipe(
              map((phref) => {
                if (!phref) {
                  return;
                }
                phref.xValue = String(phref.xValue);
                if (phref.xValue.length > 1) {
                  var first = phref.xValue.slice(0, phref.xValue.length - 1);
                  var last = phref.xValue.slice(phref.xValue.length - 1, phref.xValue.length);
                  phref.xValue = first + '.' + last + ' pH';
                } else {
                  phref.xValue = '0.' + phref.xValue + ' pH';
                }

                return phref;
              })
            )
          : of(null),
      ]);
    }

    function getFertilizationA700() {
      vm.parseFert7 = parseFert7;
      vm.ceReg = vm.currentUnit.otherValues.ceReg;
      vm.phReg = vm.currentUnit.otherValues.phReg;

      const hasXNumCE = vm.fertConfig.xNumProgramCE > 0;
      const hasXNumPH = vm.fertConfig.xNumProgramPH > 0;

      if (!hasXNumCE && !hasXNumPH) {
        return fertilizerFactory
          .getConfigFertilizers$(vm.currentUnit.id, { add: ['fertilizer', 'agitators'] })
          .pipe(map((res) => [res]));
      }

      const fertXNum = hasXNumCE ? vm.fertConfig.xNumProgramCE : vm.fertConfig.xNumProgramPH;
      return progFactory.getProgramById$(vm.currentUnit.id, fertXNum).pipe(
        switchMap((prog) => {
          if (hasXNumCE) {
            vm.programCE = prog;
          } else {
            vm.programPH = prog;
          }

          return forkJoin(
            hasXNumCE
              ? [
                  sensorsFactory
                    .getAnalogsPage$(vm.currentUnit.id, { page: 1, limit: 1 })
                    .pipe(tap((sensor) => (vm.sensorCE = sensor.content[0]))),
                  sensorsFactory
                    .getAnalogsPage$(vm.currentUnit.id, { page: 2, limit: 1 })
                    .pipe(tap((sensor) => (vm.sensorSecurityCE = sensor.content[0]))),
                  sensorsFactory
                    .getAnalogsPage$(vm.currentUnit.id, { page: 3, limit: 1 })
                    .pipe(tap((sensor) => (vm.sensorInputCE = sensor.content[0]))),
                ]
              : [
                  sensorsFactory
                    .getAnalogsPage$(vm.currentUnit.id, { page: 4, limit: 1 })
                    .pipe(tap((sensor) => (vm.sensorPH = sensor.content[0]))),
                  sensorsFactory
                    .getAnalogsPage$(vm.currentUnit.id, { page: 5, limit: 1 })
                    .pipe(tap((sensor) => (vm.sensorSecurityPH = sensor.content[0]))),
                ]
          );
        }),
        switchMap(() =>
          fertilizerFactory.getConfigFertilizers$(vm.currentUnit.id, { add: ['fertilizer', 'agitators'] })
        )
      );
    }

    function parseFert7(val) {
      switch (vm.currentUnit.flow.fertilizationUnit) {
        case 0: // hh:mm
          return $filter('parsetime')(val, true);
        case 1: // mm' ss"
          var m = (val / 60) | 0,
            s = val % 60 | 0;
          return m + '" ' + s + "'";
        case 2: //"L",
          return val + ' l';
        case 3: //"cl"
          return val + ' cl';
      }
    }

    function getFertilizationA5500() {
      if (vm.fertConfig.xNumProgram <= 0) {
        return fertilizerFactory.getConfigFertilizers$(vm.currentUnit.id, { add: ['fertilizer', 'agitators'] }).pipe(
          tap((fert) => (vm.parameters = fert)),
          // assign values in tap and map to void array,
          // in 5500 fert is saved in another variable so we dont want
          // to do nothing in subscribe callback
          map(() => [])
        );
      }

      let analogsRequests = [
        vm.fertConfig.checkCE > 0 &&
          sensorsFactory
            .getAnalogsPage$(vm.currentUnit.id, { page: vm.fertConfig.checkCE, limit: 1 })
            .pipe(tap((sensor) => (vm.sensorCE = sensor.content[0]))),
        vm.fertConfig.securityCE > 0 &&
          sensorsFactory
            .getAnalogsPage$(vm.currentUnit.id, { page: vm.fertConfig.securityCE, limit: 1 })
            .pipe(tap((sensor) => (vm.sensorSecurityCE = sensor.content[0]))),
        vm.fertConfig.inputCE > 0 &&
          sensorsFactory
            .getAnalogsPage$(vm.currentUnit.id, { page: vm.fertConfig.inputCE, limit: 1 })
            .pipe(tap((sensor) => (vm.sensorInputCE = sensor.content[0]))),
        vm.fertConfig.checkPH > 0 &&
          sensorsFactory
            .getAnalogsPage$(vm.currentUnit.id, { page: vm.fertConfig.checkPH, limit: 1 })
            .pipe(tap((sensor) => (vm.sensorPH = sensor.content[0]))),
        vm.fertConfig.securityPH > 0 &&
          sensorsFactory
            .getAnalogsPage$(vm.currentUnit.id, { page: vm.fertConfig.securityPH, limit: 1 })
            .pipe(tap((sensor) => (vm.sensorSecurityPH = sensor.content[0]))),
      ];

      if (!analogsRequests.length) {
        return of([]);
      }
      return forkJoin(analogsRequests.filter((r) => !!r)).map(() => []);
    }

    function extractFertilizers(prog) {
      var subprogram;
      if (vm.currentUnit.type === UNITS.A_4000) {
        var subIndex = prog.xSubprogramCourse;
        subprogram = prog.subprograms[subIndex];
      } else {
        subprogram = prog;
      }

      var fertilizers = _.pickBy(subprogram, function (value, key) {
        return _.startsWith(key, 'fertilizer');
      });
      if (vm.currentUnit.type === UNITS.A_BIT) {
        prog.hasFertilization = prog.fertValue && prog.irrigation;
      }
      prog.ferilizerValues = _.values(fertilizers);
    }

    function prepareProgressBarA4(prog) {
      if (prog.preIrrigation > prog.subprograms[prog.xSubprogramCourse].value) {
        prog.irrigationProgress = Math.abs(prog.xPreIrrigation - prog.preIrrigation);
        return;
      }
      prog.total = prog.subprograms[prog.xSubprogramCourse].value;

      prog.stacked = [];

      prog.irrigationProgress = Math.abs(prog.xValue - prog.subprograms[prog.xSubprogramCourse].value);
      if (prog.preIrrigation) {
        prog.stacked.push({
          start: 0,
          end: prog.preIrrigation,
          color: 'var(--vegga-color-primary-default)',
        });
      }

      prog.stacked.push({
        start: prog.preIrrigation,
        end: prog.subprograms[prog.xSubprogramCourse].value - prog.postIrrigation,
        color: 'var(--vegga-color-secondary-light)',
      });

      if (prog.postIrrigation) {
        prog.stacked.push({
          start: prog.subprograms[prog.xSubprogramCourse].value - prog.postIrrigation,
          end: prog.subprograms[prog.xSubprogramCourse].value,
          color: 'var(--vegga-color-primary-extra-dark)',
        });
      }
    }

    function prepareProgressBarBIT(prog) {
      prog.progress = Math.abs(prog.xValue - prog.value);
      prog.total = prog.value;
      prog.parsedTotal = getParsed(prog.value, prog);
      const bars = [];

      if (prog.preIrrigation) {
        bars.push({
          start: 0,
          end: prog.preIrrigation,
          color: 'var(--vegga-color-primary-default)',
        });
      }

      bars.push({
        start: prog.preIrrigation,
        end: prog.value - prog.postIrrigation - prog.preIrrigation,
        color: 'var(--vegga-color-secondary-light)',
      });

      if (prog.postIrrigation) {
        bars.push({
          start: prog.value - prog.postIrrigation - prog.preIrrigation,
          end: prog.value,
          color: 'var(--vegga-color-primary-extra-dark)',
        });
      }
      prog.stacked = bars;
    }

    function prepareProgressBar(prog) {
      var total = Number(prog.preIrrigation + prog.value);
      prog.total = total;
      prog.parsedTotal = getParsed(total, prog);
      prog.stacked = [];
      if (!prog.postIrrigation > 0) {
        prog.stacked.push({
          value: Number(prog.preIrrigation - prog.xPrePostIrrigation),
          parsedValue: getParsed(prog.preIrrigation - prog.xPrePostIrrigation, prog),
          type: 'info',
        });
        prog.stacked.push({
          value: Number(prog.value - prog.xValue),
          parsedValue: getParsed(prog.value - prog.xValue, prog),
          type: 'info',
        });
      } else {
        prog.stacked.push({
          value: Number(prog.preIrrigation - prog.xPrePostIrrigation),
          parsedValue: getParsed(prog.preIrrigation - prog.xPrePostIrrigation, prog),
          type: 'info',
        });
        if (prog.xValue <= prog.postIrrigation) {
          prog.stacked.push({
            value: Number(prog.value - prog.postIrrigation),
            parsedValue: getParsed(prog.value - prog.postIrrigation, prog),
            type: 'info',
          });
          prog.stacked.push({
            value: prog.postIrrigation - prog.xValue,
            parsedValue: getParsed(prog.postIrrigation - prog.xValue, prog),
            type: 'info',
          });
        } else {
          prog.stacked.push({
            value: Number(prog.value - prog.xValue),
            parsedValue: getParsed(prog.value - prog.xValue, prog),
            type: 'info',
          });
        }
      }
    }

    function formatFertilizerUnit(value, prog) {
      var unity;
      switch (vm.currentUnit.type) {
        case UNITS.A_4000:
          unity = prog.subprograms[prog.xSubprogramCourse].fertUnit;
          switch (unity) {
            case 0:
              return getTimeFromMin(value) + ' hh:mm';
            case 1:
              return getTimeFromMin(value) + ' mm:ss'; // Seconds to min.
            case 4:
              return value + ' l';
            case 8:
              return value + ' cl';
            case 32:
              return value + ' l/Ha';
            case 128:
              return value + ' dl';
          }
          break;
        case UNITS.A_2500:
          unity = vm.currentUnit.fertilizer.fertUnits;
          switch (unity) {
            case 0: // hh:mm
              return getTimeFromMin(value / 60);
            case 1: // l
              return value / 100 + ' l';
            case 2: // l/Ha
              return value / 100 + ' l/Ha';
            case 3: // ' "
              return getTimeFromMin(value);
          }
          break;
        case UNITS.A_BIT:
          switch (vm.fertUnit) {
            case 0: // hh:mm
              return getTimeFromMin(value / 60);

            case 1: // l
              switch (vm.fertDecimals) {
                case 0:
                  return value + ' l';
                case 1:
                  return value / 10 + ' l';
                case 2:
                  return value / 100 + ' l';
              }
              break;
            case 2: // l/Ha
              switch (vm.fertDecimals) {
                case 0:
                  return value + ' l/Ha';
                case 1:
                  return value / 10 + ' l/Ha';
                case 2:
                  return value / 100 + ' l/Ha';
              }
              break;
            case 3: // ' "
              return getTimeFromMin(value);
          }
          break;
      }

      return value;
    }

    function getFertF5Text55(fert) {
      switch (fert.xState) {
        case 0:
          return '-';
        case 1:
          return $filter('translate')('filters.wait');
        case 2:
          return vm.formatFert55(fert.xVolume, true);
        case 3:
          return $filter('translate')('general.pause');
      }
    }

    function formatFert55(val, f5) {
      var format = vm.fertConfig.fertUnits;
      var decimals = vm.fertConfig.fertDecimals;
      if (f5) {
        format = vm.fertConfig.fertUnitsF5;
        decimals = vm.fertConfig.fertDecimalsF5;
      }
      switch (format) {
        case 0: // hh:mm
          return getTimeFromMin(val / 60);
        case 1: // mm' ss"
          var m = (val / 60) | 0,
            s = val % 60 | 0;
          return m + '" ' + s + "'";
        case 2: // l
          switch (decimals) {
            case 0:
              return val + ' l';
            case 1:
              return val / 10 + ' l';
            case 2:
              return val / 100 + ' l';
          }
          break;
        case 3: // l/Ha
          switch (decimals) {
            case 0:
              return val + ' l/Ha';
            case 1:
              return val / 10 + ' l/Ha';
            case 2:
              return val / 100 + ' l/Ha';
          }
          break;
      }
    }

    function formatSignedIrr(value) {
      return value >> 8;
    }

    function formatSignedFert(value) {
      return value & 0x00ff;
    }

    function getParsed(value, prog) {
      var unity;
      switch (vm.currentUnit.type) {
        case UNITS.A_4000:
          unity = prog.subprograms[prog.xSubprogramCourse].unit;
          if (value === null) value = prog.subprograms[prog.xSubprogramCourse].value;
          if (value < 0) value = 0;
          switch (unity) {
            case 0:
              return getTimeFromMin(value) + ' hh:mm';
            case 1:
              return getTimeFromMin(value / 60) + ' mm:ss'; // Seconds to min.
            case 2:
              return value + ' m3';
            case 4:
              return value + ' l';
            case 16:
              return value + ' m3/Ha';
          }
          break;
        case UNITS.A_2500:
          unity = prog.unit;
          switch (unity) {
            case 0: //hh:mm
              return getTimeFromMin(value / 60);
            case 1: //m3
              return value + ' m3';
            case 2: //m3/Ha
              return value + ' m3/Ha';
            case 4: // ' ''
              return getTimeFromMin(value);
            case 16: //hh:mm/Ha
              return getTimeFromMin(value / 60);
          }
          break;
        case UNITS.A_BIT:
          unity = prog.unit;
          switch (unity) {
            case 0: //hh:mm
              return getTimeFromMin(value / 60) + 'hh:mm';
            case 1: //m3
              return value / 1000 + ' m3';
            case 2: //m3/Ha
              return value / 1000 + ' m3/Ha';
            case 4: // ' ''
              return getTimeFromMin(value);
            case 16: //hh:mm/Ha
              return getTimeFromMin(value / 60) + 'hh:mm/Ha';
          }
          break;
      }
    }

    function getTotalFert(value, prog) {
      var total = prog.subprograms[prog.xSubprogramCourse].value - prog.preIrrigation - prog.postIrrigation;
      var prop = formatSignedIrr(value);
      var fertProp = formatSignedFert(value);

      return (total / prop) * fertProp;
    }

    function getCalculatedFert(value, prog) {
      var total = prog.subprograms[prog.xSubprogramCourse].value - prog.preIrrigation - prog.postIrrigation;
      var irrigation = total - prog.xValue;
      var prop = formatSignedIrr(value);
      var fertProp = formatSignedFert(value);

      return (irrigation / prop) * fertProp;
    }

    function getTimeFromMin(min) {
      const formattedMinutes = Math.round(min);
      if (_.isNumber(min)) {
        if (vm.currentUnit.type === UNITS.A_4000) {
          return utilsFactory.fromMinutesToHHMM(formattedMinutes);
        }
        return progFactory.getTimeFromMins(formattedMinutes).format('HH:mm');
      }
      return formattedMinutes;
    }

    function endAlarmCE() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        switch (vm.currentUnit.type) {
          case UNITS.A_7000:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 15;
            manualFactory.genericManualAction(params.deviceId, params).then(() => {});
            break;
        }
        modalInstance.result.then(() => {});
      });
    }

    function endAlarmPH() {
      $confirm({
        text: $filter('translate')('manual.send-manual'),
        title: $filter('translate')('manual.manual-agronic'),
        ok: $filter('translate')('manual.si'),
        cancel: $filter('translate')('manual.no'),
      }).then(function () {
        var modalInstance = manualFactory.showLoadingBar();
        switch (vm.currentUnit.type) {
          case UNITS.A_7000:
            var params = {};
            params.type = vm.currentUnit.type;
            params.deviceId = vm.currentUnit.id;
            params.action = 16;
            manualFactory.genericManualAction(params.deviceId, params).then(() => {});
            break;
        }
        modalInstance.result.then(() => {});
      });
    }

    $scope.$on('formUpdated', (e, args) => {
      vm.formFertilizer = args;
    });

    $scope.$on('$destroy', function () {
      vm.destroy$.next();
      vm.destroy$.complete();
      vm.fertilizersFacade.fertilizerResponse.clear();
    });

    $rootScope.$on('showSkeleton', (ev, data) => {
      vm.showSkeleton = data;
    });
  }
})();
