import { UNITS } from '../utils/units.enum';
import { getContainer } from '@vegga/front-store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

(function () {
  'use strict';

  angular
    .module('agronicwebApp')

    .controller('equipoMapController', equipoMapController);

  equipoMapController.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    'mapsFactory',
    '$filter',

    'manualFactory',
    '$confirm',
    '$timeout',
    '$anchorScroll',
  ];

  function equipoMapController(
    $rootScope,
    $scope,
    $state,
    mapsFactory,
    $filter,

    manualFactory,
    $confirm,
    $timeout,
    $anchorScroll
  ) {
    const vm = $scope.$parent.vm;
    let currentMarker;
    let selectedUnit;
    vm.destroyMapDevices$ = new Subject();

    activate();

    /*******************/

    function activate() {
      vm.mapsFacade = getContainer().resolve('mapsLegacyFacade');

      initSubscriptions();

      vm.addMarkerE = addMarker;
      vm.saveEquipo = saveEquipo;
      vm.manualStop = manualStop;
      vm.manualExitStop = manualExitStop;
      vm.manualOut = manualOut;
      vm.manualExitOut = manualExitOut;
      vm.UNITS = UNITS;
    }

    function loadEquiposLayer() {
      if (vm.map?.id) {
        vm.mapsFacade.loadMapDevices(vm.map.id);
      }
    }

    function initSubscriptions() {
      vm.mapsFacade.mapDevices$.pipe(takeUntil(vm.destroyMapDevices$)).subscribe((devices) => {
        vm.unitsInMap = vm.units.filter(({ sync }) => devices.find(({ deviceId }) => deviceId === sync?.deviceId));

        loadMarkersToLayer(devices);
        vm.map.addLayer(vm.equiposLayer);
      });
    }

    function loadMarkersToLayer(devices) {
      if (!devices) return;
      vm.equiposLayer.clearLayers();
      devices.forEach((devicesInfo) => {
        const unit = vm.units.filter((unit) => unit.id === Number(devicesInfo.deviceId));

        if (!_.isEmpty(unit)) {
          const devices = L.marker(new L.LatLng(devicesInfo.latitude, devicesInfo.longitude));
          const className = getEquipoStateClass(unit[0]);
          devices.setIcon(createEquipoIcon(className));
          devices.properties = {};
          devices.properties.id = devicesInfo.id;
          devices.properties.deviceid = devicesInfo.deviceId;

          vm.equiposLayer.addLayer(devices);

          const stringPopup = `<div class="title-popup"><div class="img-logo"> ${unit[0].name} </div></div>`;
          const mypopup = L.popup({ closeButton: false, className: 'popup-map-sector' }).setContent(stringPopup);
          devices.bindPopup(mypopup);
        }
      });
      vm.equiposLayer.on({ click: showEquipoInfo });
      vm.equiposLayer.on({ mouseover: changeStyleOver });
      vm.equiposLayer.on({ mouseout: changeStyleOut });
    }

    function changeStyleOver(event) {
      event.layer.openPopup();
    }

    function changeStyleOut(event) {
      event.layer.closePopup();
    }

    function getEquipoStateClass(unit) {
      switch (unit.status) {
        case 'systemStop':
        case 'definitiveStopMalfunction':
          return 'stop';
        case 'outService':
          return 'out';
        case 'notconnected':
          return 'nok';
        case 'ok':
          if (unit.irrigation) {
            return 'irrig';
          } else {
            return 'noirrig';
          }
        default:
          return 'malfunction';
      }
    }

    function showEquipoInfo(e) {
      clearSectorSelection();
      const unit = _.filter(vm.units, { id: Number(e.layer.properties.deviceid) });
      if (!_.isEmpty(unit)) {
        vm.currentUnit = selectedUnit = unit[0];
        $timeout(() => {
          vm.map.invalidateSize();
          const overlay = document.getElementById('mapToolsOverlay');
          const overlayWidth = overlay.shadowRoot.querySelector('.vegga-overlay__container').offsetWidth / 2;

          const offset = [-Math.abs(overlayWidth), 0]; // Desplazamiento en píxeles
          const targetPoint = vm.map.project(e.latlng, vm.map.getZoom()).subtract(offset);
          const targetLatLng = vm.map.unproject(targetPoint, vm.map.getZoom());
          vm.map.setView(targetLatLng, vm.map.getZoom());
        }, 200);
        currentMarker = e.layer;
        showOtherSectorFromUnit(vm.currentUnit.id);

        vm.overlay.show();
        $scope.$emit('editSectionChange', {
          type: 1,
          value: vm.currentUnit,
          elementsInMap: vm.unitsInMap,
        });
        $state.go(
          'maps.unit',
          { unit: vm.currentUnit, map: vm.map, elementsInMap: vm.unitsInMap },
          { reload: 'maps.unit' }
        );
      }
    }

    function showOtherSectorFromUnit(id) {
      vm.areasLayer.eachLayer(function (l) {
        let area = l.toGeoJSON().features[0].properties;
        if (Number(area.device) === id) {
          l.setStyle({ color: '#ffc72c', weight: 3 });
        }
      });
    }

    function clearSectorSelection() {
      vm.areasLayer.eachLayer(function (l) {
        l.setStyle({ color: 'white', weight: 1 });
      });
    }

    function saveMarker(latLng) {
      const params = {};
      params.lat = latLng.lat;
      params.lng = latLng.lng;
      params.mapId = vm.map.id;
      params.deviceId = vm.unit.id;
      params.userId = $rootScope.user.id;
      params.type = 0;
      return mapsFactory.saveMarker(vm.map.id, params);
    }

    function addMarker() {
      const event = vm.props;
      const className = getEquipoStateClass(vm.unit);
      event.layer.setIcon(createEquipoIcon(className));

      saveMarker(event.latlng).then((resp) => {
        event.layer.properties = {};
        event.layer.properties.id = resp.id;
        event.layer.properties.deviceid = resp.deviceId;
        vm.mapsFacade.addMarker(resp.plain());
        $scope.$emit('completedSave');
        vm.equiposLayer.addLayer(event.layer);
        event.layer.toggleEdit();
        vm.map.off('editable:drawing:commit');
      });
    }

    function initAddMarker() {
      vm.filter.comp.units = true;
      vm.filter.comp.active = true;
    }

    const createEquipoIcon = function (labelClass) {
      const content = `<div class="map-equipo-marker ${labelClass}"></div>`;
      return L.divIcon({
        className: 'custom-marker',
        html: content,
      });
    };

    function saveEquipo() {
      const params = {};
      params.id = currentMarker.properties.id;
      params.deviceId = currentMarker.properties.deviceid = vm.currentUnit.id;
      mapsFactory.updateMarker(vm.map.id, params).then(() => {
        closeEquipoInfo();
      });
    }

    function closeEquipoInfo() {
      vm.areaInfo = false;
      $anchorScroll();
      $timeout(() => {
        vm.map.invalidateSize();
      }, 200);
      clearSectorSelection();
    }

    function deleteEquipoMarker() {
      mapsFactory.deleteMarker(vm.map.id, currentMarker.properties.id).then(() => {
        vm.equipoInfo = false;
        vm.mapsFacade.deleteMarker(currentMarker.properties.id);
        vm.equiposLayer.removeLayer(currentMarker);
        closeEquipoInfo();
      });
    }

    /* Manual functionality */

    function manualStop() {
      $confirm({
        text: $filter('translate')(`maps.confirm.send_order`),
        title: 'Manual AGRONIC',
        ok: $filter('translate')(`general.y`),
        cancel: $filter('translate')(`general.y`),
      }).then(function () {
        const modalInstance = manualFactory.showLoadingBar();
        let params = {};
        switch (vm.currentUnit.type) {
          case 2:
            manualFactory.stopUnit(vm.currentUnit);
            break;
          case 3:
            params = {};
            params.type = '3';
            params.deviceId = vm.currentUnit.id;
            params.action = 7;
            params.parameter1 = 1;
            manualFactory.genericManualAction(params.deviceId, params);
            break;
        }
        modalInstance.result.then(() => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function manualExitStop() {
      $confirm({
        text: $filter('translate')(`maps.confirm.send_order`),
        title: 'Manual AGRONIC',
        ok: $filter('translate')(`general.y`),
        cancel: $filter('translate')(`general.n`),
      }).then(function () {
        const modalInstance = manualFactory.showLoadingBar();
        let params = {};
        switch (vm.currentUnit.type) {
          case 2:
            manualFactory.exitStopUnit(vm.currentUnit);
            break;
          case 3:
            params = {};
            params.type = '3';
            params.deviceId = vm.currentUnit.id;
            params.action = 7;
            params.parameter1 = 0;
            manualFactory.genericManualAction(params.deviceId, params);
            break;
        }
        modalInstance.result.then(() => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function manualOut() {
      $confirm({
        text: $filter('translate')(`maps.confirm.send_order`),
        title: 'Manual AGRONIC',
        ok: $filter('translate')(`general.y`),
        cancel: $filter('translate')(`general.n`),
      }).then(function () {
        const modalInstance = manualFactory.showLoadingBar();
        manualFactory.outUnit(vm.currentUnit);
        modalInstance.result.then(() => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    function manualExitOut() {
      $confirm({
        text: $filter('translate')(`maps.confirm.send_order`),
        title: 'Manual AGRONIC',
        ok: $filter('translate')(`general.y`),
        cancel: $filter('translate')(`general.n`),
      }).then(function () {
        const modalInstance = manualFactory.showLoadingBar();
        manualFactory.exitOutUnit(vm.currentUnit);
        modalInstance.result.then(() => {
          $rootScope.$broadcast('reload', { message: 'refreshing' });
        });
      });
    }

    $scope.$on('closeEquipo', () => {
      closeEquipoInfo();
    });
    $scope.$on('deleteEquipo', () => {
      vm.overlay.dismiss();
      deleteEquipoMarker();
    });

    $scope.$on('addEquipoMarker', (event, args) => {
      vm.props = args;
      $scope.$emit('groupElementsInMap', vm.unitsInMap);
      initAddMarker();
    });

    $scope.$on('loadEquiposLayer', () => {
      if (vm.filter.comp.active && vm.filter.comp.units) loadEquiposLayer();
    });

    $scope.$on('remarkSectors', () => {
      if (selectedUnit !== undefined && selectedUnit !== null) {
        showOtherSectorFromUnit(selectedUnit.id);
      }
    });

    $scope.$on('reloadMap', () => {
      if (vm.filter.comp.active && vm.filter.comp.units) loadEquiposLayer();
    });

    $scope.$on('$destroy', function () {
      vm.destroyMapDevices$.next();
      vm.destroyMapDevices$.complete();
    });
  }
})();
